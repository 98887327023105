<footer class="container" style="max-width: 100%;">
  <div>
    <div class="col-12 col-md-12 col-lg-12 footer_nav">
      <img src="/assets/images/rent-it.svg" style="height: 40px;">
      <small class="mb-1" style="text-decoration: underline;">
        <a [routerLink]="[publicLinks.LEGAL.TERMS_CONDITIONS]" routerLinkActive="active" class="mr-2">Terms</a>
        <a [routerLink]="[publicLinks.LEGAL.PRIVACY_POLICY]" routerLinkActive="active" class="mr-2">Privacy</a>
        <a href="https://rentreef.freshdesk.com" target="_blank" class="mr-2">Support</a>
      </small>
      <hr style="width: 95%;border-top: 1px solid var(--muted_primary_color);opacity: 0.2;">
      <small class="d-block mb-1" style="color: var(--banner_color);">©2021 All Rights Reserved. <b>RentReef®</b> ―
        If only I could rent it. </small>
      <!-- SOCIAL ICONS -->
      <div class="pb-2">
        <a href="https://facebook.com/Rent-Reef-612237622766331" style="color: var(--banner_color);">
          <i class="fab fa-facebook mr-2"></i>
        </a>
        <a href="https://twitter.com/ReefRent" style="color: var(--banner_color);">
          <i class="fab fa-twitter mr-2"></i>
        </a>
        <a href="https://www.instagram.com/rentreef/" style="color: var(--banner_color);">
          <i class="fab fa-instagram mr-2"></i>
        </a>
        <a href="https://www.linkedin.com/company/rentreef/" style="color: var(--banner_color);">
          <i class="fab fa-linkedin-in mr-2"></i>
        </a>
      </div>
    </div>

    <!-- HIDDEN -->
    <div class="col-6 col-md hidden">
      <h5>ABOUT</h5>
      <ul class="list-unstyled text-small">
        <li><a [routerLink]="[publicLinks.COMPANY.HOW_IT_WORKS]" routerLinkActive="active">How It Works</a>
        </li>
        <li><a [routerLink]="[publicLinks.LEGAL.PRIVACY_POLICY]" routerLinkActive="active">Privacy
            Policy</a></li>
        <li><a [routerLink]="[publicLinks.LEGAL.TERMS_CONDITIONS]" routerLinkActive="active">Terms &
            Conditions</a></li>
      </ul>
    </div>
    <div class="col-6 col-md hidden">
      <h5>SUPPORT</h5>
      <ul class="list-unstyled text-small">
        <li><a href="#">Help Center</a></li>
        <li><a href="#">FAQ</a></li>
        <li><a href="#">Feedbacks</a></li>
      </ul>
    </div>
    <div class="col-6 col-md hidden">
      <h5>SOCIAL</h5>
      <ul class="_JHhden list-unstyled text-small">
        <li><a href="https://www.facebook.com/Rent-Reef-612237622766331"><img src="/assets/images/facebook.png"></a>
        </li>
        <li><a href="https://www.instagram.com/rentreef/"><img src="/assets/images/instagram.png"></a></li>
        <li><a href="https://twitter.com/ReefRent"><img src="/assets/images/twitter.png"></a></li>
      </ul>
    </div>
  </div>
</footer>