<div class="how-it-works">
    <h4>How it works</h4>
    <h5>Owner</h5>
    <ol>
        <li>
            Take some photos of your items and list them on our website. Write ashort description, set a price, specify
            your
            location and then post!
        </li>
        <li>
            Respond to borrowers and approve rental orders.
        </li>
        <li>
            Set a time and location that is convenient to you and the borrower andlend your item.
        </li>
        <li>
            Earn money through our secure payment system at the start of theapproved order date.
        </li>
    </ol>

    <h5>
        Renter
    </h5>
    <ol>
        <li>
            Search for any item on our website.
        </li>
        <li>
            Choose an item and the duration of time you would like to borrow it for.<br />
            Wait for the lender’s approval.
        </li>
        <li>
            Arrange a time and place for the exchange of the item.
        </li>
        <li>
            Return the item to the lender at the end of your borrowing period.
        </li>
    </ol>

    <p>
        All users are verified. If an item that you received from a lender was damaged orif your item was returned to
        you broken, we will ensure that you arecompensated.<br />
        All payment transactions are made through our secure website.<br />
        If you have any issues with your rental experience, RentReef provides 24/7customer service so we can address
        your concerns.<br />
        Lenders are encouraged to make a profile so the borrowers can get an ideaabout who they are borrowing from.
        Reviews from previous borrowers will alsoappear on these profile pages which will help build a rapport between
        the lenderand other potential borrowers.
    </p>



</div>