import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/auth.service';
import { ProfileService } from '@app/core/auth/profile.service';
import { LoadFormService } from '@app/core/services/load-form.service';
import { AddListingService } from '@app/core/services/add-listing.service';
import { User } from '@app/models/user.model';

@Component({
  selector: 'app-new-listing-init',
  templateUrl: './new-listing-init.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./new-listing-init.component.css']
})
export class NewListingInitComponent implements OnInit {
  basicInfoForm: FormGroup;
  downloadURLs = [];
  constructor(
    private profileSvc: ProfileService,
    private loadFormService: LoadFormService,
    private addListingService: AddListingService,
    private router: Router,
    private ngbModal: NgbModal,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.basicInfoForm = this.loadFormService.createNewListingFormObject('basic-info');
    // const userID = JSON.parse(localStorage.user).uid;
    // this.profileSvc.getUser(userID).subscribe(doc => {
    //   this.addListingService
    //     .createNewListing(userID, doc.data().stripe_account_ID)
    //     .then(result => {
    //       console.log('result', result);
    //       this.itemId = result.id;
    //       if (localStorage.user !== undefined) {
    //         this.addListingService.createUserListingRelationship(result.id, userID);
    //       }
    //       this.router.navigate(['add-listing', result.id, 'basic-info']);
    //     })
    //     .catch(newListingErr => {
    //       if (!(this.itemId === null) && !(this.itemId === '')) {
    //         this.deleteSvc.deleteListing(this.itemId);
    //       }
    //       console.log('newListingErr', newListingErr);
    //     });
    // });
  }

  onImageUploaded(event) {
    this.downloadURLs.push(event);
    let downloadURLs: any;
    if (this.basicInfoForm.controls.images.value) {
      downloadURLs = [...this.basicInfoForm.controls.images.value];
    } else {
      this.basicInfoForm.controls.images.patchValue([]);
      downloadURLs = [];
    }

    downloadURLs.push(event);
    this.basicInfoForm.controls.images.patchValue(downloadURLs);
    console.log(
      'this.basicInfoForm.controls.images.value',
      this.basicInfoForm.controls.images.value
    );
  }

  saveListing(form) {
    console.log('form', form);
    const userID = JSON.parse(localStorage.user).uid;
    this.profileSvc.getUser(userID).subscribe(doc => {
      this.addListingService
        .createNewListing(userID, doc.data().stripe_account_ID, form.value)
        .then(result => {
          this.dismiss();
          // this.itemId = result.id;
          if (localStorage.user !== undefined) {
            this.addListingService.createUserListingRelationship(result.id, userID);
          }
          this.router.navigate(['add-listing', result.id]);
        })
        .catch(newListingErr => {
          // if (!(this.itemId === null) && !(this.itemId === '')) {
          //   this.deleteSvc.deleteListing(this.itemId);
          // }
          // console.log('newListingErr', newListingErr);
        });
    });
  }

  dismiss() {
    this.ngbModal.dismissAll();
  }

  clickGetStarted() {
    this.router.navigate(['/my-listing'])
      .then(() => {
        this.authService.user
          .pipe(take(1))
          .subscribe((user: User) => {
            this.profileSvc.onboardingDone(user.uid);
          });
        this.dismiss();
      });
  }
}
