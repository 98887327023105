import { Injectable } from '@angular/core';
import * as algoliasearch from 'algoliasearch';
import { take } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { environment } from '@app/environments/environment';
import { AlgoliaQueryOption } from '@app/models/algolia-query-option.model';
import { User } from '@app/models/user.model';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class AlgoliaService {
  private client: any;
  private index: any;
  private indexRentalPriceAsc: any;
  private indexRentalPriceDesc: any;

  constructor(private authService: AuthService,
    private fun: AngularFireFunctions) {
    this.client = algoliasearch.default(environment.algolia.appId, environment.algolia.apiKey);

    this.index = this.client.initIndex(environment.algolia.indexKey);
    this.indexRentalPriceAsc = this.client.initIndex(environment.algolia.indexRentalPriceKey.replace('{sort}', 'asc'));
    this.indexRentalPriceDesc = this.client.initIndex(environment.algolia.indexRentalPriceKey.replace('{sort}', 'desc'));
  }

  searchHits(query: string, queryOption?: AlgoliaQueryOption): any {
    return this.search(query, queryOption).then(data => data.hits);
  }

  search(query: string, queryOption: AlgoliaQueryOption = {}): Promise<any> {
    return new Promise<any>((resolve) => {
      this.authService.user
        .pipe(take(1))
        .subscribe((user: User) => {
          if (!queryOption.filters) {
            queryOption.filters = 'status:true';
          }

          // if (user) {
          //   queryOption.filters = `${queryOption.filters} AND NOT user:${user.uid}`;
          // }
          if (queryOption.priceRange && queryOption.priceRange.min != null && queryOption.priceRange.max != null) {
            queryOption.filters += ` AND rentalPrice:${queryOption.priceRange.min || 0} TO ${queryOption.priceRange.max || 0}`;
          }
          if (queryOption.rentalPriceSort) {
            if (queryOption.rentalPriceSort === 'asc') {
              resolve(this.indexRentalPriceAsc.search(query, {
                filters: queryOption.filters,
                page: queryOption.page,
                hitsPerPage: queryOption.hitsPerPage
              }));
            } else {
              resolve(this.indexRentalPriceDesc.search(query, {
                filters: queryOption.filters,
                page: queryOption.page,
                hitsPerPage: queryOption.hitsPerPage
              }));
            }
          } else {
            resolve(this.index.search(query, {
              filters: queryOption.filters,
              page: queryOption.page,
              hitsPerPage: queryOption.hitsPerPage
            }));
          }

        });
    });
  }
}
