import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { ApplicationLoadingService } from '@app/core/services/application-loading.service';
import { RentsService } from '@app/core/services/rents.service';
import { User } from '@app/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { takeUntil, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: ['./review-modal.component.scss']
})
export class ReviewModalComponent implements OnInit {

  unsubscribe$ = new Subject();

  user$: Observable<User>;
  rentsToReview: any[];

  loading = true;
  selectedToReview$ = new BehaviorSubject(null);
  index = 0;

  constructor(
    private authService: AuthService,
    private rentsService: RentsService,
    private ngbModal: NgbModal,
    private applicationLoadingService: ApplicationLoadingService,
    private changeRef: ChangeDetectorRef
  ) {
    this.user$ = this.authService.user;
    this.applicationLoadingService.toggleLoading(true);
  }

  ngOnInit() {
    this.rentsService.getRentsToBeReviewed()
      .pipe(take(1))
      .subscribe(data => {
        if (data.length) {
          this.rentsToReview = data;
          this.selectedToReview$.next(this.rentsToReview[this.index]);
        } else {
          // this.ngbModal.dismissAll();
          this.loading = false;
        }
        this.applicationLoadingService.toggleLoading(false);
      });

    this.selectedToReview$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => {
        this.loading = !data;
        this.changeRef.detectChanges();
      });
  }

  moveIndex() {
    if (
      this.rentsToReview.length
      && this.rentsToReview.length > this.index + 1
    ) {
      this.index++;
      this.selectedToReview$.next(this.rentsToReview[this.index]);
    } else {
      this.ngbModal.dismissAll();
    }
  }

}
