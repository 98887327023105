export const BusinessConfig = {
    COMPANY_NAME: 'RentReef',
    Listing: {
        MaxNameSize: 80,
        Currency: {
            Default: 'SGD',
            Supported: ['SGD', 'PHP']
        }
    },
};
