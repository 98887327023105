import { CLOUD_IMAGE_URL } from './../../../helpers/images';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Pipe({
  name: 'cloudImage'
})
export class CloudImagePipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    let finalUrl = value;
    if (value && value.startsWith(environment.firebase_new_config.bucket)) {
      // TODO handle resize args
      finalUrl = `${CLOUD_IMAGE_URL}/${finalUrl}`;
    }
    return finalUrl;
  }

}
