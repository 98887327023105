import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private afs: AngularFirestore) { }

  getSearchedList(data) {
    const obsArr = [];
    data.categories.forEach(element => {
      obsArr.push(
        this.afs
          .collection('listings', ref =>
            ref.where('category', 'array-contains', element).orderBy('createdAt', 'desc')
          )
          .snapshotChanges()
      );
    });
    return combineLatest(...obsArr);
  }
}
