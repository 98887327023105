import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map
} from 'rxjs/operators';

@Component({
  selector: 'app-custom-search',
  templateUrl: './custom-search.component.html',
  styleUrls: ['./custom-search.component.scss']
})
export class CustomSearchComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchInputNav') searchInputNav: ElementRef;
  @Output() categorySelectedOutput: EventEmitter<any> = new EventEmitter();
  @Output() removeSelectedOutput: EventEmitter<boolean> = new EventEmitter();
  @Output() search: EventEmitter<boolean> = new EventEmitter();
  @Input() categorySelected: any;
  @Input() isNav: boolean;
  public triggerList = false;
  public searchPlaceholder = `Try 'Game, Bike or Camera'`;
  public inputSearched: any = null;
  public inputSelected: any = null;
  public input: any = null;
  apiResponse: any;
  isSearching: boolean;

  constructor(private eRef: ElementRef) { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    const debounceTimeValue = 100;
    const minimumLength = -1;
    if (!this.isNav) {
      fromEvent(this.searchInput.nativeElement, 'keyup')
        .pipe(
          // get value
          map((event: any) => {
            return event.target.value;
          }),
          // if character length greater then minimumLength
          filter(res => res.length > minimumLength),
          // Time in milliseconds between key events
          debounceTime(debounceTimeValue),
          // If previous query is diffent from current
          distinctUntilChanged()
          // subscription for response
        )
        .subscribe((text: string) => {
          this.isSearching = false;
          this.triggerList = true;
          this.inputSearched = text;
        });
    } else {
      fromEvent(this.searchInputNav.nativeElement, 'keyup')
        .pipe(
          // get value
          map((event: any) => {
            return event.target.value;
          }),
          // if character length greater then minimumLength
          filter(res => res.length > minimumLength),
          // Time in milliseconds between key events
          debounceTime(debounceTimeValue),
          // If previous query is diffent from current
          distinctUntilChanged()
          // subscription for response
        )
        .subscribe((text: string) => {
          this.isSearching = false;
          this.triggerList = true;
          this.inputSearched = text;
        });
    }
  }

  ngOnChanges(): void {
    this.resetValues();
  }

  get ifLogged(): boolean {
    return localStorage.getItem('user') ? true : false;
  }

  inputEntered() {
    this.categorySelectedOutput.emit({ type: 'input', value: this.inputSelected });
    this.search.emit(true);
    this.searchInput.nativeElement.focus();
    if (this.inputSelected) {
      this.triggerList = false;
    }
  }

  focusInput() {
    this.searchPlaceholder = `Try 'Game, Bike or Camera'`;
    this.searchInput.nativeElement.focus();
  }

  onTriggerFalse(event) {
    if (event) {
      this.resetValues();
      this.triggerList = false;
    }
  }

  onRemoveInput() {
    this.input = null;
    this.inputSelected = null;
    this.inputSearched = null;
  }

  onCategorySelected(event) {
    this.categorySelectedOutput.emit(event);
    switch (event.type) {
      case 'input':
        this.inputSelected = event.value;
        this.searchInput.nativeElement.focus();
        break;
      case 'list':
        this.inputSelected = event.value.name;
        break;
      default:
        break;
    }
    //  else if (event === this.inputSearched) {
    //   this.inputSelected = event;
    // } else {
    //   this.inputSelected = event.name;
    // }
    this.search.emit(true);
    if (this.inputSelected) {
      this.triggerList = false;
    }
  }

  checkSearch(event) {
    if (event) {
      setTimeout(() => {
        this.isSearching = event;
      });
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.resetValues();
      this.triggerList = false;
    }
  }

  private resetValues() {
    if (this.categorySelected) {
      if (this.categorySelected.length === 0) {
        this.searchPlaceholder = `Try 'Drone'`;
      }
    }
  }
}