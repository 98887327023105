import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateDelta } from '@app/models/date-delta';

export const DEFAULT_DATE_FORMAT = 'M-D-YYYY';
export const UI_DATE_FORMAT = 'MMM DD, YYYY';
export const UI_DATE_FORMAT_SINGLE_DIGIT_DAY = 'MMM DD, YYYY';
export const ISO_DATE = 'YYYY-MM-DDThh:mmTZD';
export const TIME_WITH_AMPM = 'h:mm A';

@Injectable()
export class DateUtil {

    constructor() { }

    parseToDefaultFormat(dateString: string, fromDateFormat: string = ''): Date {
        return this.parse(dateString, fromDateFormat);
    }

    parseUI(dateString: string, fromDateFormat: string): Date {
        return this.parse(dateString, fromDateFormat, UI_DATE_FORMAT);
    }

    parseNgbDate(ngbDate: NgbDate, fromDateFormat: string): Date {
        return this.parse(`${ngbDate.month}-${ngbDate.day}-${ngbDate.year}`, fromDateFormat)
    }

    parse(dateString: string, fromDateFormat: string, toDateFormat?: string): Date {
        return this.isValidDate(dateString, fromDateFormat)
            ? moment(dateString, toDateFormat || fromDateFormat).toDate()
            : null;
    }

    isValidDate(dateString: string, dateFormat: string = DEFAULT_DATE_FORMAT): boolean {
        return moment(dateString, dateFormat, true).isValid();
    }

    format(date: Date, dateFormat: string = UI_DATE_FORMAT): string {
        return moment(date).format(dateFormat);
    }

    formatNgbDate(ngbDate: NgbDate, fromDateFormat: string, toDateFormat: string = DEFAULT_DATE_FORMAT): string {
        return moment(`${ngbDate.month}-${ngbDate.day}-${ngbDate.year}`, fromDateFormat, true).format(toDateFormat);
    }

    createNgbDate(date: Date): NgbDate {
        return new NgbDate(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate()
        );
    }

    getDifference(date1: Date, date2: Date): DateDelta {
        const delta = date1.getTime()
            - date2.getTime();
        const s = delta / 1000;
        const min = delta / (1000 * 60);
        const hr = delta / (1000 * 3600);
        const day = delta / (1000 * 3600 * 24);
        return {
            ms: delta,
            s,
            min,
            hr,
            day
        }
    }

    getDifferenceUiString(date1: Date, date2: Date): string {
        const dateDelta = this.getDifference(date1, date2);

        const result = dateDelta.day >= 1 ? dateDelta.day
            : dateDelta.hr >= 1 ? dateDelta.hr
                : dateDelta.min >= 1 ? dateDelta.min
                    : dateDelta.s;

        const resultStr = dateDelta.day >= 1 ? 'day'
            : dateDelta.hr >= 1 ? 'hour'
                : dateDelta.min >= 1 ? 'minute'
                    : 'sec';

        const displayStr = resultStr === 'sec'
            ? 'now'
            : `${Math.floor(result)} ${resultStr}${result > 1 ? 's' : ''} ago`

        return displayStr;
    }

    generateArrayOfYears() {
        let max = new Date().getFullYear();
        let min = max - 100
        var years = []

        for (let i = max; i >= min; i--) {
            years.push(i)
        }
        return years
    }

}
