import { PreloadingStrategy, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class OptInPreloadStrategy implements PreloadingStrategy {
    preload(route: Route, load: () => Observable<any>): Observable<any> {
        return route != null && route.data && route.data.preload ? load() : EMPTY;
    }

}
