<div *ngIf="authService.user | async; then authenticated; else guest">
  <!-- template will replace this div -->
</div>

<!-- User NOT logged in -->
<ng-template #guest>
  <!-- <div class="container">
    <button type="button" class="btn btn-outline-danger btn-block" (click)="loginUser('google')">
      <i class="fa fa-google"></i> Connect Google df
    </button>
    <button type="button" class="btn btn-primary btn-block" (click)="loginUser('facebook')">
      <i class="fa fa-facebook"></i> Connect Facebook
    </button>
    <span>Or</span>
    <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
      <div class="form-group">
        <input
          type="email"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Email"
          formControlName="email"
        />
        <div *ngIf="loginForm.controls.email.touched">
          <small *ngIf="loginForm.controls.email.hasError('required')" class="text-danger"
            >Email Required</small
          >
          <small *ngIf="loginForm.controls.email.hasError('email')" class="text-danger"
            >Please provide a valid email</small
          >
        </div>
      </div>
      <div class="form-group">
        <input
          type="password"
          class="form-control"
          id="exampleInputPassword1"
          placeholder="Password"
          formControlName="password"
        />
        <div *ngIf="loginForm.controls.password.touched">
          <small *ngIf="loginForm.controls.password.hasError('required')" class="text-danger"
            >Password Required</small
          >
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-outline-success btn-block form-group"
        [disabled]="!loginForm.valid"
      >
        Login
      </button>
    </form>
    <button type="submit" class="btn btn-outline-success btn-block" (click)="openRegisterModal()">
      Register
    </button>
  </div> -->
  <!-- updated login modal -->
  <div>
    <div class="modal-header">
      <!-- <h1 class="display-3 modal-title"
        style="color:#444444!important;padding-top: 0px;font-size: 42px!important;text-align: center;width: 100%;">
        Welcome to RentIt
      </h1> -->
      <label>No Account yet? <span class="cta" (click)="openRegisterModal()">Create One Now</span></label>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
        <svg class="feather close-modal-icon">
          <use xlink:href="../../../../assets/img/feather-sprite.svg#x" />
        </svg>
      </button>
    </div>
    <div class="modal-body">
      <div class="social-wrap">
        <a class="Gydhwj secondary SocialAuthButton-sc-15rly9t-0 hadGcB" id="fs-signup-google" color="copyOne"
          href="JavaScript:Void(0);" (click)="loginUser('google')">
          <svg class="sc-bdVaJa iUJjjs" height="34" icon="icon-google" width="18" viewBox="0 0 1024 1024">
            <path class="sc-bwzfXH fxarpp"
              d="M512 197.973c96.142 0 160.996 41.529 197.973 76.231l144.498-141.084c-88.747-82.489-204.231-133.12-342.471-133.12-200.249 0-373.191 114.916-457.387 282.169l165.547 128.569c41.529-123.449 156.444-212.764 291.84-212.764v0z"
              fill="rgb(234, 67, 53)"></path>
            <path class="sc-bwzfXH fxarpp"
              d="M1003.52 523.378c0-42.098-3.413-72.818-10.809-104.676h-480.711v190.009h282.169c-5.689 47.218-36.409 118.329-104.676 166.116l161.564 125.156c96.711-89.316 152.462-220.729 152.462-376.604v0z"
              fill="rgb(66, 133, 244)"></path>
            <path class="sc-bwzfXH fxarpp"
              d="M220.729 613.262c-10.809-31.858-17.067-65.991-17.067-101.262s6.258-69.404 16.498-101.262l-165.547-128.569c-34.702 69.404-54.613 147.342-54.613 229.831s19.911 160.427 54.613 229.831l166.116-128.569z"
              fill="rgb(251, 188, 5)"></path>
            <path class="sc-bwzfXH fxarpp"
              d="M512 1024c138.24 0 254.293-45.511 339.058-124.018l-161.564-125.156c-43.236 30.151-101.262 51.2-177.493 51.2-135.396 0-250.311-89.316-291.271-212.764l-165.547 128.569c84.196 167.253 256.569 282.169 456.818 282.169v0z"
              fill="rgb(52, 168, 83)"></path>
          </svg>
          <span>Continue with Google</span>
        </a>
        <a class="Gydhwj secondary SocialAuthButton-sc-15rly9t-0 hadGcB" id="fs-signup-facebook" color="white"
          href="JavaScript:Void(0);" (click)="loginUser('facebook')">
          <svg class="sc-bdVaJa iUJjjs" width="52px" height="52px" viewBox="0 0 52 52" version="1.1"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
              <linearGradient x1="50%" y1="121.807692%" x2="50%" y2="1.49423077%" id="linearGradient-1">
                <stop stop-color="#155CDE" offset="0%"></stop>
                <stop stop-color="#1F7FE5" offset="27.8%"></stop>
                <stop stop-color="#279CEB" offset="56.9%"></stop>
                <stop stop-color="#2CAFEF" offset="82%"></stop>
                <stop stop-color="#2EB5F0" offset="100%"></stop>
              </linearGradient>
            </defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="icons8-facebook" fill-rule="nonzero">
                <path
                  d="M52,26 C52,39.35 41.95,50.34 29,51.83 C28.02,51.94 27.01,52 26,52 C24.29,52 22.62,51.83 21,51.51 C9.03,49.19 0,38.65 0,26 C0,11.64 11.64,0 26,0 C40.36,0 52,11.64 52,26 Z"
                  id="Path" fill="url(#linearGradient-1)"></path>
                <path
                  d="M36.8,30.05 L36.04,32.05 C35.6,33.22 34.46,34 33.19,34 L29,34 L29,51.83 C28.02,51.94 27.01,52 26,52 C24.29,52 22.62,51.83 21,51.51 L21,34 L18.05,34 C16.36,34 15,32.66 15,31 L15,29 C15,27.34 16.36,26 18.05,26 L21,26 L21,20 C21,14.49 25.49,10 31,10 L34,10 C36.21,10 38,11.79 38,14 C38,16.21 36.21,18 34,18 L31,18 C29.9,18 29,18.9 29,20 L29,26 L33.95,26 C36.08,26 37.55,28.09 36.8,30.05 Z"
                  id="Path" fill="#FFFFFF"></path>
              </g>
            </g>
          </svg>
          <span>Continue with Facebook</span>
        </a>
      </div>
      <div class="line-sep-wrapper-form">
        <div class="line-separator"></div>
        <h2 style="color: #484848;">or</h2>
        <div class="line-separator"></div>
      </div>
      <div class="login-form-wrap">
        <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
          <mat-form-field class="Bsuwn example-full-width">
            <input matInput placeholder="Email" value="" formControlName="email" />
          </mat-form-field>
          <mat-form-field class="Bsuwn example-full-width">
            <input matInput placeholder="Password" value="" type="password" formControlName="password" />
            <svg class="feather pw-icon hidden">
              <use xlink:href="../../../../assets/img/feather-sprite.svg#eye" />
            </svg>
          </mat-form-field>
          <button class="Gydhwj signin" mat-button [disabled]="!loginForm.valid">Sign in</button>
        </form>
        <a class="forgot-pwd" (click)="forgotPassword()">Forgot Password?</a>
        <p class="text-danger mt-4">{{forgotPasswordStatus}}</p>
      </div>
    </div>
  </div>
  <!-- updated login modal end -->
</ng-template>

<!-- User logged in -->
<ng-template #authenticated>
  <div class="_loggedin" *ngIf="authService.user | async as user">
    <div class="modal-header">
      <h1 class="display-3 modal-title"
        style="color:#444444!important;padding-top: 0px;font-size: 42px!important;text-align: left;width: 100%;">
        Profile
      </h1>
      <div class="row">
        <div class="col-lg-9 user-name-wrap">
          <span>{{ user.firstName }} {{ user.lastName }}</span>
          <!-- Todo: dynamic date for member since, timestamp for lastActive -->
          <!-- <span class="sub" style="opacity:0.8">Last active {{user.lastActive}}</span> -->
          <!-- <span class="sub" style="opacity:0.8">Member since September 12, 2019</span> -->
        </div>
        <div class="col-lg-3">
          <img class="prof-pic" [src]="user.photoURL | imageUrl | async" />
        </div>
      </div>
    </div>
    <div class="modal-body">
      <ul>
        <li class="link" (click)="navigateToRoute('profile')">
          Account
          <span class="sub">Manage account information</span>
        </li>
        <!-- <li>
          Security
          <span class="sub">Lorem ipsum dolor sit amet elit</span>
        </li> -->
        <li class="link" (click)="navigateToRoute('profile/pay-in-out-method')">
          Payment & Payouts
          <span class="sub">Add a credit card to rent an item or a bank account to receive funds.</span>
        </li>
        <!-- <li class="link" (click)="navigateToRoute('profile/preference')">
          Preferences
          <span class="sub">Review how to manage your cash</span>
        </li> -->
      </ul>
      <button class="rui-btn rui-btn--primary" (click)="logout()" style="box-shadow: 4px 4px 0px #ffb612;">
        <i class="fas fa-logout"></i>
        Logout
      </button>
    </div>
  </div>

  <!-- <div *ngIf="authService.user | async as user">
    <h3>Howdy, {{ user.displayName }}</h3>
    <img [src]="user.photoURL" />
    <p>UID: {{ user.uid }}</p>
    <p>Favorite Color: {{ user?.favoriteColor }}</p>
    <button (click)="authService.signOut()">Logout</button>
  </div> -->
</ng-template>