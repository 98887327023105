import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  task: AngularFireUploadTask;
  snapshot: Observable<any>;
  downloadURL: any;
  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private fun: AngularFireFunctions) { }

  updatedProfile(data, uid) {
    return this.afs.doc(`users/${uid}`).update(data);
  }
  updateUserProfilePic(url, uid) {
    this.afs.doc(`users/${uid}`).update({ photoURL: url });
  }

  onboardingDone(uid) {
    return this.afs.doc(`users/${uid}`).update({ onboarding: true });
  }

  getUser(userId) {
    return this.afs
      .collection('users')
      .doc(userId)
      .get();
  }

  sendPhoneVerification() {
    return this.fun.httpsCallable('sendProfileNumberVerification')({});
  }

  verifyPhone(code: string) {
    return this.fun.httpsCallable('verifyPhoneVerification')({ code });
  }
}
