interface String {
    foo(): number;
    timeToDate(): Date;
}

interface Date {
    toTimeFormat(): string;
    addDays(days: number): Date;
    minusDays(days: number): Date;
    addYears(years: number): Date;
    addMinutes(minutes: number): Date;
    subtractYears(years: number): Date;
    toStringFormat(format: string): string;
}

Date.prototype.addDays = function (days: number) {
    const dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);

    return dat;
};

Date.prototype.minusDays = function (days: number) {
    const dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() - days);

    return dat;
};
Date.prototype.addYears = function (years: number) {
    const yr = new Date(this.valueOf());
    yr.setFullYear(yr.getFullYear() + years);

    return yr;
};

Date.prototype.addMinutes = function (minutes: number) {
    return new Date(new Date(this.valueOf()).getTime() + minutes * 60000);
};

Date.prototype.subtractYears = function (years: number) {
    const yr = new Date(this.valueOf());
    yr.setFullYear(yr.getFullYear() - years);

    return yr;
};

// tslint:disable-next-line:only-arrow-functions
Date.prototype.toStringFormat = function (format: string) {
    return '';
};

Date.prototype.toTimeFormat = function () {
    const today = new Date(this.valueOf());
    const h = today.getHours() < 10 ? '0' + today.getHours() : today.getHours();
    const m = today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes();
    const s = today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds();
    return `${h}:${m}:${s}`;
};

String.prototype.timeToDate = function () {
    return new Date(`01/01/1970 ${this.valueOf()}`);
};
