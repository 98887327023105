import { AuthService } from '@app/core/auth/auth.service';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  UrlTree
} from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.user.pipe(map(
      user => !!user || this.router.createUrlTree(['/'])
    ));
  }
}
