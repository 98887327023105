import { Component, Input, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { HubspotService } from '@app/core/services/hubspot.service';
import { EventTypes } from '@app/helpers/hubspot';
import { IdentityDetails } from '@app/models/hubspot-events';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { RegisterComponent } from './../register/register.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [`
    .dark-modal {
      height: 92vh!important;
      justify-content: center!important;
      display: flex!important;
      align-items: center!important;
      border: none!important;
    }
  `],
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  private readonly notifier: NotifierService;
  @Input() redirectTo: string = '';
  forgotPasswordStatus: string; // is this the right way to display a message

  constructor(
    public authService: AuthService,
    public ngbModal: NgbModal,
    private router: Router,
    private hubspotService: HubspotService,
    private ngZone: NgZone,
    private notifierService: NotifierService,
    public activeModal: NgbActiveModal,
  ) {
    this.notifier = notifierService;
  }

  loginForm = new FormGroup({
    email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    password: new FormControl('', Validators.compose([Validators.required]))
  });

  ngOnInit() { }

  loginUser(oAuth?) {
    switch (oAuth) {
      case 'google':
        this.authService
          .googleLogin()
          .then(res => {
            this.authService.updateUserData(res.user, res.additionalUserInfo);
            this.identifyLoginUser({ email: res.user.email });
            this.redirect();
          })
          .catch(err => {
            this.notifier.notify('error', err.message);
          });
        break;
      case 'facebook':
        this.authService
          .facebookLogin()
          .then(res => {
            this.authService.updateUserData(res.user, res.additionalUserInfo);
            this.identifyLoginUser({ email: res.user.email });
            this.redirect();
          })
          .catch(err => {
            this.notifier.notify('error', err.message);
          });
        break;
      default:
        if (this.loginForm.valid) {
          const email = this.loginForm.value.email;
          const password = this.loginForm.value.password;
          this.authService
            .signIn({ email, password })
            .then(response => {
              this.identifyLoginUser({ email });
              this.redirect();
            })
            .catch(registerError => {
              console.log('err', registerError);
            });
        } else {
          this.ngbModal.dismissAll();
          this.notifier.notify(
            'error',
            'There is an error in registration please fill up all required fields!'
          );
          return;
        }
        break;
    }
  }

  openRegisterModal() {
    this.ngbModal.dismissAll();
    this.ngbModal.open(RegisterComponent);
  }

  dismiss() {
    this.ngbModal.dismissAll();
  }

  navigateToRoute(route: string) {
    this.dismiss();
    this.router.navigate([route]);
  }

  identifyLoginUser(identityDetails: IdentityDetails) {
    if (identityDetails.email) {
      this.hubspotService.pushEvent(EventTypes.IDENTIFY, identityDetails);
    }
  }

  redirect() {
    if (this.redirectTo && this.redirectTo.trim()) {
      this.navigateToRoute(`${this.redirectTo.trim()}`);
    }
  }

  logout() {
    this.authService.signOut()
      .then(() => {
        this.activeModal.close();
      });
  }

  async forgotPassword() {
    const email = this.loginForm.value.email
    this.forgotPasswordStatus = await this.authService.sendPasswordResetEmail(email)
    setTimeout(() => {
      this.forgotPasswordStatus = ''
    }, 4000)
  }

}
