<div *ngIf="user$ | async as user" class="container">
  <div class="leave-review rui-modal">
    <!-- start of separate component -->
    <!-- create loop from items -->
    <div *ngIf="!loading">
      <app-review-item *ngIf="selectedToReview$ | async as item; else noItems" [user]="user"
        [rentDetail]="item.rentDetail" [listingId]="item.listingId" [index]="index" [totalLength]="rentsToReview.length"
        (clickAction)="moveIndex()">
      </app-review-item>
      <ng-template #noItems>
        <span>no items to review</span>
      </ng-template>
    </div>
  </div>
</div>