<section class="public-pages">
    <div class="container-sm cvjEh policy-page">
        <h2>Rent Reef Privacy Policy</h2>
        <b>Last Modified {{ lastUpdated | date: 'MMMM d, y' }}</b>
        <hr />
        <p>This Website Privacy Policy (“Privacy Policy”) provides information regarding how RentReef. (“RentReef” or
            “we”) collects, uses and transfers personally identifiable information (“Personal Data”) from you through
            RentReef’s publicly accessible website (<a href="https://rentreef.com">https://rentreef.com</a>) (the
            “Website” or “Site”). By using this
            Website, you consent to the collection and use of your Personal Data as described herein. If you do not
            agree with the terms set out herein, please do not use this website. If RentReef decides to make changes to
            this Privacy Policy, RentReef will post the changes on this website so that you will know what Personal Data
            we collect, and how we use it. We therefore encourage you to periodically look for updates to this Privacy
            Policy.</p>
        <p>This Privacy Policy describes how RentReef makes use of your Personal Data and your rights regarding the
            same. The following information is included in this Privacy Policy:</p>
        <div>
            <ol>
                <li>The Service</li>
                <li>Collection of Personal Data</li>
                <li>Use and Purpose of Collected Personal Data</li>
                <li>Legal Basis of Processing Personal Data</li>
                <li>Keeping Your Personal Data Secure</li>
                <li>Personal Data Shared With Third Parties</li>
                <li>Retention of Personal Data</li>
                <li>Your Rights With Respect to Personal Data</li>
                <li>How to Contact RentReef Regarding Personal Data</li>
            </ol>
        </div>

        <div>
            <h4>1. The Service</h4>

            <p>RentReef is a peer to peer marketplace for owners and renters. We allow businesses and websites to
                collect
                and analyze data about how their users are interacting with their services. RentReef does this by
                collecting
                data on what users are doing, including but not limited to what webpages they visit, what users click
                on,
                where those users are located, what browser or platform those users are using, and many other forms of
                behavioral or personal data.</p>
        </div>

        <div>
            <h4>2. Collection of Personal Data</h4>

            <p>When you request information on the Website, subscribe to a mailing list or for a service, respond to an
                online survey or otherwise actively send us data, we usually collect data such as:</p>

            <div>
                <ul>
                    <li>your name, e-mail address,</li>
                    <li>mailing address,</li>
                    <li>telephone number, and</li>
                    <li>certain information related to the items you list or rent.</li>
                </ul>
            </div>

            <p>
                Additionally, when you rent an item or attempt to rent an item through the Site, we collect certain
                information from you, including your name, billing address, shipping address, payment information
                (including
                credit card numbers, email address, and phone number.
            </p>

            <p>
                You may opt out of providing information by not entering it when asked and, if such information is
                required
                in order to allow us to respond to your inquiry, you will receive a notice advising you of this.
            </p>

            <p>
                When you visit the Website, we also track information on your computer and your internet connection,
                such as
                but not limited to:
            </p>

            <div>
                <ul>
                    <li>the IP address of your computer and internet service provider, when you access the site, the IP
                        address of websites from which you connect to our Website, and</li>
                    <li>the computer systems you are using and your use or and preferences on our Site.</li>
                </ul>
            </div>

            <p>
                We may also automatically record certain information from your device by using various types of
                technology,
                including:
            </p>

            <div>
                <ul>
                    <li>clear gifs, web beacons or pixels,</li>
                    <li>information from your device by using various types of technology, including clear gifs or web
                        beacons,</li>
                    <li>your IP address or other device address or ID,</li>
                    <li>web browser and/or device type,</li>
                    <li>the web pages or sites that you visit just before or just after you use our Website,</li>
                    <li>the pages or other content you view or otherwise interact with through our Websites, and</li>
                    <li>the dates and times that you visit, access, or use our Website.</li>
                </ul>
            </div>

            <p>
                We also may use these technologies to collect information regarding your interaction with email
                messages,
                such as whether you opened, clicked on, or forwarded a message. This information is gathered from all
                users
                and may be connected with other information about you.
            </p>

            <p>
                When you use our Website, we may send one or more cookies (small text files containing a string of
                alphanumeric characters) to your device. We may use both session cookies and persistent cookies. A
                session
                cookie disappears after you close your browser. A persistent cookie remains after you close your browser
                and
                may be used by your browser on subsequent visits to our Sites. Your web browser may provide you with
                some
                options regarding cookies. Please note that if you delete, or choose not to accept, cookies from our
                Websites, you may not be able to utilize the features to their fullest potential. We may use third party
                cookies in connection with our Website.
            </p>
        </div>


        <div>
            <h4>3. Use and Purpose of Collected Personal Data</h4>

            <p>We use the information that we collect on or through our Website to:</p>

            <div>
                <ul>
                    <li>operate, maintain, enhance and provide all features of our Website,</li>
                    <li>provide services and information that you request,</li>
                    <li>screen transactions for potential risk or fraud,</li>
                    <li>respond to comments and questions and otherwise to provide support to users,</li>
                    <li>to process and deliver entries and rewards in connection with promotions that we may offer from
                        time to time,</li>
                    <li>understand and analyze the usage trends and preferences of our users, to improve our Website,
                        and to develop new products, services, features, and functionality, and</li>
                    <li>contact you for administrative purposes such as customer service or to send communications,
                        including updates on promotions and events, relating to products and services offered by us and
                        by third parties.</li>
                </ul>
            </div>
        </div>

        <div>
            <h4>4. Legal Basis of Processing Personal Data</h4>

            <p>
                If you are visiting our Website from the European Economic Area, we typically collect and/or process
                your Personal Data when we have a legitimate business interest or your specific consent.
            </p>
        </div>

        <div>
            <h4>5. Keeping Your Personal Data Secure</h4>

            <p>
                To protect your Personal Data we use appropriate technical measures corresponding to the type of
                Personal Data and the risks associated with processing such Personal Data. In the event of a data breach
                we will contact those affected and to the extent required the required regulator as required by
                applicable law.
            </p>
        </div>

        <div>
            <h4>6. Personal Data Shared With Third Parties</h4>

            <p>
                Unless you have opted out from receiving commercial communications from RentReef, we also may share
                contact information (that we collect through various marketing efforts) with trusted business partners,
                such as system integrators, distributors and referral partners. We may share personal information for
                legal, protection, and safety purposes; to comply with laws; to respond to lawful requests and legal
                processes; to protect our rights and property including but not limited to enforcing our agreements,
                policies, and terms of service. We may also share information in an emergency.
            </p>

            <p>
                We may share or transfer your information in connection with a prospective or actual sale, merger,
                transfer or other reorganization of all or parts of our business. Also, we reserve the right to fully
                use and disclose any information that is not in personally identifiable form (such as statistics and
                survey results that do not identify you individually by name). We may also share aggregated and/or
                anonymized data with others for their own uses. We may also share any information that you voluntarily
                choose to include in a publicly accessible area of the Sites and will be available to anyone who has
                access to that content, including other users.
            </p>
        </div>

        <div>
            <h4>7. Retention of Personal Data</h4>

            <p>
                When you rent an item through the Site, we will maintain your Order Information for our records unless
                and until you ask us to delete this information.
            </p>

            <p>
                We may retain your Personal Data in connection with our ongoing legitimate business interest. When an
                ongoing legitimate business interest with respect to your Personal Data no longer exists we will delete
                such Personal Data.
            </p>
        </div>

        <div>
            <h4>8. Your Rights With Respect to Personal Data</h4>

            <div>
                <ul>
                    <li>
                        Object to processing and opt-out. In connection with promotions or other projects, we may ask
                        you specifically whether you have objections against a certain kind of data use or sharing. If
                        you opt-out under such circumstances, we will respect your decision. To opt out of receiving
                        commercial communications from RentReef please click on the “opt-out” link in the communication
                        or, please contact us at privacy@rentreef.com. Please note that our affiliates and other data
                        recipients have their own data privacy policies, which may differ from ours and you would have
                        to contact them separately with respect to opt-out requests.
                    </li>
                </ul>
            </div>

            <p>
                In connection with promotions or other projects, we may ask you specifically whether you have objections
                against a certain kind of data use or sharing. If you opt out, we may still send you non-marketing
                emails. Non-marketing emails include emails about your account, transactions and our business dealings
                with you.
            </p>

            <div>
                <ul>
                    <li>
                        Correct or Update.You may send requests about personal information to our Contact Information
                        below. You can request to change contact choices, opt-out of our sharing with others, and update
                        your personal information.
                    </li>
                </ul>
            </div>

            <p>
                If personal information you have submitted through the site is no longer accurate, current, or complete,
                and you wish to update it, please send an e-mail to privacy@rentreef.com. Upon appropriate request we
                will usually update or amend your information, but we reserve the right to use information obtained
                previously to verify your identity or take other actions that we believe are appropriate.
            </p>

            <p>
                You may request that we delete your personal information as well as request that we make your personal
                information available for porting.
            </p>

            <div>
                <ul>
                    <li>
                        Withdraw consent.You may withdraw your consent at any time, but such withdrawal shall only apply
                        with respect to any processing after such withdrawal and you may request that RentReef make your
                        Personal Data portable and make such Personal Data available to you.
                    </li>
                    <li>
                        Right to complain.You may complain to a data protection authority regarding the use or
                        collection of your personal information. Please contact your local data protection authority for
                        more information regarding how to file such a complaint.
                    </li>
                </ul>
            </div>
        </div>

        <div>
            <h4>9. How to Contact RentReef Regarding Personal Data</h4>

            <p>We welcome your comments or questions about this privacy policy. You may also contact us as follows:</p>

            <p>
                Email Address: <a href="mailto:support@rentreef.com">support@rentreef.com</a>.
            </p>
        </div>
    </div>
</section>