export const PUBLIC_LINKS = {
    COMPANY: {
        ABOUT_US: '/company/about-us',
        HOW_IT_WORKS: '/company/how-it-works'
    },
    LEGAL: {
        PRIVACY_POLICY: '/legal/privacy-policy',
        TERMS_CONDITIONS: '/legal/terms'
    }
};
