export enum UserReservationRoleType {
  PERSONAL = 'personal',
  RENTER = 'renter'
}

export interface UserReservationRoleTypeButton {
  type: UserReservationRoleType;
  label: string;
}

export const userReservationRoleTypeSelectionButtons =
  Object.keys(UserReservationRoleType)
    .map(key => ({
      type: UserReservationRoleType[key],
      label: UserReservationRoleType[key].charAt(0).toUpperCase() + UserReservationRoleType[key].slice(1)
    }));

export const getUserReservationRoleTypeButton = (
  userReservationRoleType: UserReservationRoleType
): UserReservationRoleTypeButton => {
  return {
    type: userReservationRoleType,
    label: userReservationRoleType.charAt(0).toUpperCase()
      + userReservationRoleType.slice(1)
  }
}