export const LISTINGS = 'listings';
export const RENTS = 'rents';
export const USERS = 'users';
export const RENT_DETAILS = 'rentDetails';
export const ENTRIES = 'entries';
export const CHATS = 'chats';
export const CHAT_ROOMS = 'chat-rooms';
export const REVIEWS = 'reviews';

export const DATA = 'data';
export const NOTIFICATIONS = 'notifications';
