import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';

@Component({
  selector: 'app-rating-stars-label',
  templateUrl: './rating-stars-label.component.html',
  styleUrls: ['./rating-stars-label.component.scss']
})
export class RatingStarsLabelComponent {

  @Input() rating: number = 5;
  @Input() maxStars: number = 5;

  constructor() { }

  getReviewStars() {
    const noStar = 'fa fa-star-o';
    const starClass = 'fa fa-star';
    const halfStarClass = `${starClass}-half-o`;
    const result = [];

    for (let index = 0; index < this.maxStars; index++) {
      result.push(noStar);
    }

    return result.map(
      (item, idx) => this.rating - idx >= 1
        ? starClass
        : this.rating - idx > 0
          ? halfStarClass
          : item
    );
  }

}
