<div class="restaurant-lookup">
  <div class="selectionWrapper" #selectionWrap>
    <app-skeleton></app-skeleton>
    <div class="active-result" *ngIf="inputSearched" (click)="onOptionSelect('input', inputSearched)">
      {{ inputSearched }}
    </div>
    <app-skeleton [show]="inputSearched != null && isSearching">
      <ul>

        <li class="active-result" *ngFor="let list of lists" (click)="onOptionSelect('list', list)">
          <img appLazyload [useEmptyState]="true" [src]="getFirstImageUrl(list.images) | imageUrl | async" width="30px"
            height="30px" class="mr-2" />
          {{ list.name }}
        </li>
        <li class="active-result title" *ngIf="footer">
          {{
          lists && lists.length === 0 && !isSearching ? 'Sorry, no items found.' : 'Searching...'
          }}
        </li>
        <!-- <li class="active-result title" *ngIf="lists && lists.length === 0 && !isSearching">
          Sorry, no items found.
        </li> -->
      </ul>
    </app-skeleton>
  </div>
</div>

<!-- result-selected -->
<!-- active-result -->