import { Injectable } from '@angular/core';

// firebase import
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';

// notifier import
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class DeleteListingService {
  private readonly notifier: NotifierService;
  constructor(private afs: AngularFirestore, private notifierService: NotifierService) {
    this.notifier = notifierService;
  }

  // delete data from with listing with the DocId of listingID
  deleteListing(listingID) {
    this.afs.collection('listings').doc(`${listingID}`).delete()
      .then((res) => console.log('Document successfully deleted! listing ID:', res))
      .catch(error => this.notifier.notify('error', error.message));
  }
}
