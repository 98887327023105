<nav fxLayout="row">

  <!-- <div fxLayout fxHide.gt-sm>
    <a *ngIf="ifLogged" class="nav-link profile" href="javascript:void(0)" data-toggle="modal" data-target="#loginModal"
      (click)="login()" style="font-size: smaller; text-align: center;">
      <img [src]="profilePic" alt="..." />
    </a>
  </div> -->

  <div fxFlex.gt-sm="0 0 auto" [ngClass.lt-md]="'logo--centered'">
    <a class="navbar-brand" [routerLink]="['/']">
      <img src="/assets/images/rent-it.svg" class="logo" />
    </a>
  </div>

  <div *ngIf="ifLogged" fxHide.lt-md>
    <app-custom-search (categorySelectedOutput)="onCategorySelected($event)"
      (removeSelectedOutput)="onCategoryRemove($event)" (search)="searchListing($event)" [isNav]="true">
    </app-custom-search>
  </div>

  <div fxHide.lt-md fxFlex fxLayout="row" fxLayoutAlign="space-between none" [ngClass]="{'logged-in': ifLogged}">
    <div class="menu-links">
      <a class="rui-btn nav-link" routerLink="/listing" routerLinkActive="active">Marketplace</a>
      <a class="rui-btn nav-link" *ngIf="ifLogged" routerLink="/my-listing" routerLinkActive="active">My Listings</a>
      <a class="rui-btn nav-link" *ngIf="!ifLogged" [routerLink]="[publicLinks.COMPANY.ABOUT_US]">About</a>
      <a class="rui-btn nav-link" *ngIf="!ifLogged" href="https://rentreef.freshdesk.com" target="_blank">Help</a>
      <a class="rui-btn nav-link" *ngIf="ifLogged" routerLink="/messaging" routerLinkActive="active">Inbox
        <span *ngIf="(unreadMessagesCount$ | async) as unreadMessagesCount">
          ({{unreadMessagesCount}})
        </span>
      </a>
    </div>

    <div fxLayout>
      <a style="margin-right: 10px; line-height: 35px;" class="rui-btn nav-link" (click)="login()" data-toggle="modal"
        data-target="#loginModal" *ngIf="!ifLogged">Sign In</a>
      <button class="signup-btn rui-btn rui-btn--outline--invert btn btn-outline-success my-2 my-sm-0" type="button"
        data-toggle="modal" data-target="#signUpModal" (click)="onClickSignup()" *ngIf="!ifLogged">
        Sign Up ― It's Free
      </button>

      <a *ngIf="ifLogged" class="nav-link profile" href="javascript:void(0)" data-toggle="modal"
        data-target="#loginModal" (click)="login()" style="font-size: smaller; text-align: center;">
        <span class="d-md-none d-lg-none d-xl-block">{{ userName }}</span>
        <img [src]="profilePic" alt="..." />
      </a>
    </div>
  </div>

  <div fxHide.gt-sm class="menu-button">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
      aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        class="feather feather-menu">
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg>
    </button>
  </div>

  <ng-container>
    <div class="collapse navbar-collapse menu-links menu-links--mobile" id="navbarsExampleDefault"
      [ngClass]="{'logged-in': ifLogged}">

      <div>
        <ul class="navbar-nav">

          <!-- <li class="nav-item">
            <a *ngIf="ifLogged" class="nav-link profile" href="javascript:void(0)" data-toggle="modal"
              data-target="#loginModal" (click)="login()" style="font-size: smaller; text-align: center;">
              <span>{{ userName }}</span>
              <img [src]="profilePic" alt="..." />
            </a>
          </li> -->

          <li class="nav-item" routerLink="/listing" routerLinkActive="active">
            <a class="rui-btn nav-link" style="line-height: 35px;">Marketplace</a>
          </li>

          <li class="nav-item" *ngIf="ifLogged" (click)="login()" href="javascript:void(0)" data-toggle="modal"
            data-target="#loginModal">
            <a class=" rui-btn nav-link" style="line-height: 35px;">
              My Account
            </a>
          </li>

          <li class="nav-item" *ngIf="!ifLogged" [routerLink]="[publicLinks.COMPANY.ABOUT_US]"
            routerLinkActive="active">
            <a class="rui-btn nav-link" style="line-height: 35px;">About</a>
          </li>
          <li class="nav-item" *ngIf="!ifLogged">"
            <a href="https://rentreef.freshdesk.com" target="_blank" class="rui-btn nav-link"
              style="line-height: 35px;">Help</a>
          </li>
          <li class="nav-item" *ngIf="!ifLogged">
            <a style="margin-right: 10px; line-height: 35px;" class="rui-btn nav-link" (click)="login()"
              data-toggle="modal" data-target="#loginModal">Sign In</a>
          </li>
          <li class="nav-item" *ngIf="ifLogged" routerLink="/my-listing" routerLinkActive="active">
            <a class="rui-btn nav-link">My Listings</a>
          </li>

          <li class="nav-item d-none d-lg-block" routerLink="/messaging" routerLinkActive="active">
            <a class="rui-btn nav-link" style="line-height: 35px;">
              Inbox
              <span *ngIf="(unreadMessagesCount$ | async) as unreadMessagesCount">
                ({{unreadMessagesCount}})
              </span>
            </a>
          </li>

          <li *ngIf="showLeaveReview" class="nav-item" routerLinkActive="active">
            <button class="rui-btn rui-btn--outline nav-link review">Leave Review</button>
          </li>

          <li class="nav-item sign-up" *ngIf="!ifLogged">
            <button class="signup-btn rui-btn rui-btn--outline--invert btn btn-outline-success my-2 my-sm-0"
              type="button" data-toggle="modal" data-target="#signUpModal" (click)="onClickSignup()">
              Sign Up ― It's Free
            </button>
          </li>

          <li class="nav-item search" *ngIf="ifLogged">
            <app-custom-search (categorySelectedOutput)="onCategorySelected($event)"
              (removeSelectedOutput)="onCategoryRemove($event)" (search)="searchListing($event)" [isNav]="true">
            </app-custom-search>
          </li>

          <li *ngIf="devMode" class="d-none">
            <a [matMenuTriggerFor]="menu" class="nav-notifications rui-btn nav-link"
              style="line-height: 35px;height: 90%;right: -2px;position: relative;border-left: 2px solid var(--primary_color);padding-left: 0;">
              <!-- Notifications
              <span *ngIf="(unreadNotificationsCount$ | async) as unreadNotificationsCount">
                ({{unreadNotificationsCount}})
              </span> -->
              <span *ngIf="(unreadNotificationsCount$ | async) as unreadNotificationsCount"
                matBadge="{{unreadNotificationsCount}}" matBadgeColor="warn" matBadgeOverlap="false"
                class="notification-badge">
                <svg matBadge="15" matBadgeColor="warn" class="notification-nav" fill="none" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round">
                  <use xlink:href="./assets/img/feather-sprite.svg#bell"></use>
                </svg>
              </span>
            </a>
            <!-- TODO insert unread notifications badge -->
            <mat-menu class="nav-notifications-menu" #menu="matMenu" (click)="$event.stopPropagation()"
              style="max-width: 100%;">
              <!-- <mat-list (click)="$event.stopPropagation()"> -->
              <div *ngFor="let appNotification of (notifications$ | async)">
                <app-notification-list-item [appNotification]="appNotification"
                  (notificationAction)="handleNotificationAction($event, appNotification)" style="width: 100%">
                </app-notification-list-item>
              </div>
              <!-- </mat-list> -->
            </mat-menu>
          </li>

        </ul>
      </div>

    </div>
  </ng-container>

  <ng-container *ngIf="!ifLogged">
    <a class="btn btn-outline-success my-2 my-sm-0 btn-primary start-earning-cta" href="javaScript:void(0);"
      (click)="onNewListingEvent()">Start Earning
      <img class="monetized-icon" src="../../../../assets/images/trophy.svg">
    </a>
  </ng-container>

  <ng-container *ngIf="showVerificationAlert && !alertClosed && ifLogged">
    <ngb-alert *ngIf="!alertClosed" class="verification-alert" (closed)="alertClosed=true">
      <strong class="d-block">Complete Verification</strong> You're one step away from completing your profile <a
        routerLink="/profile" (click)="alertClosed=true">Verify
        Now</a>
    </ngb-alert>
  </ng-container>

</nav>