import { QueryResult } from './../../../models/query-result';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import {
  DATA,
  ENTRIES,
  LISTINGS,
  REVIEWS,
  USERS
} from '@app/helpers/firestore.keys';
import { CustomQueryOption } from '@app/models/custom-query-option.model';
import { Review } from '@app/models/review.model';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

const ADD_REVIEW = 'addReview';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  constructor(
    private afs: AngularFirestore,
    private fun: AngularFireFunctions
  ) { }

  addReview(review: Review) {
    return this.fun.httpsCallable(ADD_REVIEW)({ review });
  }

  /**
 * @deprecated The method should not be used
 */
  getListingReviews(
    listingId: string,
    queryOptions?: CustomQueryOption
  ): Observable<Review[]> {
    const reviewUsersCollection = this.afs.collection(LISTINGS)
      .doc(listingId)
      .collection(REVIEWS)
      .doc(DATA)
      .collection(USERS);

    return reviewUsersCollection.get()
      .pipe(
        map(documents => {
          const users = [];
          documents.forEach(user => users.push(user.id));
          return users;
        }),
        switchMap(users => {
          const createdOnKey = 'createdOn';
          const pageSize = queryOptions
            ? queryOptions.page * queryOptions.size : 0;
          const lowerLimit = queryOptions ? pageSize : 0;
          const upperLimit = queryOptions
            ? pageSize + queryOptions.size : 999999;
          return from(
            Promise.all(users.filter(str => !!str.trim())
              .map(user =>
                reviewUsersCollection.doc(user)
                  .collection(ENTRIES)
                  .ref
                  .orderBy(createdOnKey, 'desc')
                  .limit(1)
                  .get()
                  .then(reviewDocument => ({
                    id: reviewDocument.docs[0].id,
                    ...reviewDocument.docs[0].data()
                  } as Review))
                  .catch(() => undefined)
              )
              .filter((data, idx) => idx >= lowerLimit && idx < upperLimit)
            )
          );
        })
      );
  }

  getListingReviewsQueryResult(
    listingId: string,
    queryOptions?: CustomQueryOption
  ): Observable<QueryResult<Review[]>> {
    const reviewUsersCollection = this.afs.collection(LISTINGS)
      .doc(listingId)
      .collection(REVIEWS)
      .doc(DATA)
      .collection(USERS);

    return reviewUsersCollection.get()
      .pipe(
        map(documents => {
          const users = [];
          documents.forEach(user => users.push(user.id));
          return users;
        }),
        switchMap(users => {
          const createdOnKey = 'createdOn';
          const pageSize = queryOptions
            ? queryOptions.page * queryOptions.size : 0;
          const lowerLimit = queryOptions ? pageSize : 0;
          const upperLimit = queryOptions
            ? pageSize + queryOptions.size : Number.MAX_SAFE_INTEGER;

          const allData = users.filter(str => !!str.trim())
            .map(user =>
              reviewUsersCollection.doc(user)
                .collection(ENTRIES)
                .ref
                .orderBy(createdOnKey, 'desc')
                .limit(1)
                .get()
                .then(reviewDocument => ({
                  id: reviewDocument.docs[0].id,
                  ...reviewDocument.docs[0].data()
                } as Review))
                .catch(() => undefined)
            );

          const filteredData = Promise.all(allData
            .filter((data, idx) => idx >= lowerLimit && idx < upperLimit)
          );

          const listSize = allData.length;
          return from(
            filteredData.then(data => new Promise<QueryResult<Review[]>>(
              (res) => {
                res({
                  data,
                  size: listSize
                });
              }
            ))
          );
        })
      );
    return of({} as QueryResult<Review[]>);
  }

  getListingReviewAnalytics(listingId: string) {
    return this.afs.collection(LISTINGS)
      .doc(listingId)
      .collection(REVIEWS)
      .doc(DATA)
      .get()
      .pipe(
        map(doc => ({
          ...doc.data()
        }))
      )
  }

}
