import { Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { UserService } from '@app/core/auth/user.service';
import { AppNotificationService } from '@app/core/services/app-notification.service';
import { ListingService } from '@app/core/services/listing.service';
import { MessageService } from '@app/core/services/message.service';
import { RentsService } from '@app/core/services/rents.service';
import { AppNotificationAction } from '@app/helpers/app-notification-action.enum';
import { AppNotificationType } from '@app/helpers/app-notification-type.enum';
import { AppNotification } from '@app/models/app-notification.model';
import { User } from '@app/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as firebase from 'firebase';
import { forkJoin, Observable, of, Subject, combineLatest } from 'rxjs';
import { switchMap, take, takeUntil, mergeMap } from 'rxjs/operators';
import { PUBLIC_LINKS } from 'src/app/common/public-page-links';
import { LoginComponent } from '../login/login.component';
import { NewListingInitComponent } from '../new-listing-init/new-listing-init.component';
import { RegisterComponent } from '../register/register.component';
import { ReviewModalComponent } from './../review-modal/review-modal.component';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<boolean>();

  public profilePic = 'https://i.pinimg.com/originals/f5/05/24/f50524ee5f161f437400aaf215c9e12f.jpg';
  public userName: any;
  public inputSelected: any = null;
  onboarding = true;
  publicLinks = PUBLIC_LINKS;

  alertClosed = false;
  showVerificationAlert = false;
  showLeaveReview = false;

  unreadNotificationsCount$: Observable<number>;
  unreadMessagesCount$: Observable<number>;

  ifLogged: boolean;

  notifications$: Observable<AppNotification[]>;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private ngbModal: NgbModal,
    private rentsService: RentsService,
    private notificationService: AppNotificationService,
    private listingService: ListingService,
    private messageService: MessageService,
    private userService: UserService
  ) { }

  get ifListingOrProfile(): boolean {
    // return true;
    return this.router.url.includes('/')
      || this.router.url.includes('/results')
      || this.router.url.includes('/listing')
      || this.router.url.includes('/profile')
      || this.router.url.includes('/messaging');
  }

  get devMode() {
    return isDevMode();
  }

  ngOnInit() {
    this.authService.user
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: User) => {
        this.ifLogged = !!user;
        if (user) {
          if (user.displayName) {
            this.userName = user.displayName;
          } else {
            this.userName = `${user.firstName || ''}${user.lastName ? ' ' : ''}${user.lastName || ''}`;
          }
          this.onboarding = user.onboarding;
        }

        this.profilePic = user?.photoURL || '../../assets/images/default-avatar.svg';

        const authUser = firebase.auth().currentUser;
        if (authUser != null && user) {
          user.emailVerified = authUser.emailVerified;
          if (user && (!user.mobileVerified || !user.emailVerified)) {
            if (!this.alertClosed) {
              this.showVerificationAlert = true;
            }
          }
        }
      });

    this.notifications$ = this.notificationService.getUserNotifications();
    this.unreadNotificationsCount$ = this.notificationService.getUserUnreadNotificationsCount();
    this.unreadMessagesCount$ = this.messageService.getUserUnreadMessagesCount();

    this.evaluateShowReviewButton();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  onCategoryRemove(index) {
    this.inputSelected = null;
  }

  onCategorySelected(event) {
    this.inputSelected = event;
  }

  onClickSignup(redirect = '') {
    const modalRef = this.ngbModal.open(RegisterComponent);
    if (modalRef && redirect && redirect.trim()) {
      modalRef.componentInstance['redirectTo'] = redirect.trim();
    }
  }

  login(redirect = '') {
    const modalRef = this.ngbModal.open(LoginComponent);
    if (modalRef && redirect && redirect.trim()) {
      modalRef.componentInstance['redirectTo'] = redirect.trim();
    }
  }

  onNewListingEvent() {
    if (!this.ifLogged) {
      this.onClickSignup('my-listing');
    } else if (!this.ifLogged && !this.onboarding) {
      this.ngbModal.open(NewListingInitComponent, { backdrop: 'static' });
    } else {
      this.router.navigate(['/my-listing']);
    }
  }

  leaveReview() {
    this.ngbModal.open(ReviewModalComponent)
      .result
      .finally(() => this.evaluateShowReviewButton());
  }

  searchListing(event) {
    if (event && this.inputSelected.type === 'input') {
      this.router.navigate(['listing/results', this.inputSelected.value]);
    } else if (event && this.inputSelected.type === 'list') {
      this.router.navigate(['listing/details', this.inputSelected.value.objectID]);
    }
  }

  evaluateShowReviewButton() {
    this.rentsService.getRentsToBeReviewed()
      .pipe(take(1))
      .subscribe(data => this.showLeaveReview = !!data.length);
  }

  handleNotificationAction(
    appNotificationAction: AppNotificationAction,
    appNotification: AppNotification
  ) {
    switch (appNotificationAction) {
      case AppNotificationAction.REDIRECT:
        this.listingService
          .getListingDetails(appNotification.data.chatListingItem.listingId)
          .pipe(
            switchMap(listing => combineLatest([
              this.authService.user,
              this.userService.getUserDetails(listing.user)
            ])),
            switchMap(([user, listingUser]) =>
              this.messageService.initializeChatRoom(
                user,
                listingUser,
                appNotification.data.chatListingItem
              )
            ),
            take(1)
          )
          .subscribe(chatRoom => {
            this.router.navigate(
              [appNotification.data.route, chatRoom.id]
            )
          });
        break;
      case AppNotificationAction.RATINGS_MODAL:
        if (this.showLeaveReview) {
          this.leaveReview();
        }
        break;
      case AppNotificationAction.MODAL:
      default:
        console.log(appNotification.data);
    }
  }
}
