<div>
  <form *ngIf="!loading; else showSpinner" [formGroup]="reviewForm">
    <div class="modal-header">
      <h2 style="text-align: center;">Leave a review</h2>
    </div>
    <div *ngIf="listing$ | async as listing" class="modal-body">
      <!-- bind to actual listing -->
      <label>
        Hi {{ user.firstName }}! We would like to hear from you on your recent transaction.
        Hope you had a good one.
      </label>
      <div class="row">
        <small>#{{ rentDetail.id }}</small>
        <div class="col-lg-12">
          <label class="_Djaqe1 is-active" style="border-radius: 10px!important;">
            <label class="_su3n">
              <span>
                <div class="files image-uploads"
                  [ngStyle]="getBackgroundImageStyle(listing.images[0] | imageUrl | async)">
                </div>
              </span>
              <div class="Bwuyn">
                <!-- listing name -->
                <label>
                  {{ listing.name }}
                </label>
                <!-- listing descrioption -->
                <span class="_dhjr">
                  {{ listing.description }}
                </span>
                <!-- rent end date/return date -->
                <span>
                  <i>{{ rentDetail.endDate.toDate().toString() | timeAgo }}</i>
                </span>
              </div>
            </label>
          </label>
        </div>
        <!-- bind to form or object -->
        <div *ngFor="let item of reviewFormStars" class="col-lg-12 rating">
          <label>{{ item.label }}</label>
          <mat-star-rating class="stars" [id]="item.key" [rating]="starRatingConfig.initialRating"
            [starCount]="starRatingConfig.starCount" (ratingUpdated)="onRatingChanged($event, item.key)">
          </mat-star-rating>
        </div>
        <div class="col-lg-12 rating">
          <label>Tell us more about your experience</label>
          <textarea class="rui-textarea" formControlName="comment"></textarea>
        </div>
      </div>
    </div>
    <div class="col-lg-12 modal-footer">
      <div class="content-start">
        <!-- add dynamic count -->
        <span>{{ index + 1 }}/{{ totalLength }} item(s)</span>
      </div>
      <div class="content-end">
        <button class="rui-btn rui-btn--basic" mat-button (click)="clickSkip()">
          Skip for now
        </button>
        <button class="rui-btn rui-btn--primary" mat-button (click)="sendReview()">
          Submit
        </button>
      </div>
    </div>
  </form>
  <ng-template #showSpinner>
    <app-spinner class="review-spinner"></app-spinner>
  </ng-template>
</div>