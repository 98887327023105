<section class="public-pages">
    <div class="container-sm about-us">
    <h2>About us</h2> 
    <p>
        RentReef was founded in 2021 and is a South East Asian based company, namely Singapore and the
        Philippines, and is dedicated to finding solutions to combat overconsumption. By renting instead of buying, the
        potential of disposable products being dumped into landfills would be significantly decreased. Our company’s
        intent is to thwart overconsumption by normalizing and commercialising the idea of people sharing their
        belongings with others. In this way, sellers are able to earn money while allowing borrowers to save money.
    </p>

    <h3>RentReef</h3>
    <p>
        The company’s roots, specifically the name RentReef, is embedded with inspiration from the two founding
        countries, Singapore and the Philippines, which are part of the coral triangle. The coral triangle consists of
        the most important reef systems in the world and we are devoted to doing our part in protectingthese natural
        wonders of the ocean. Every year, about 300 million tons of plasticare produced globally and about 8 million
        tons of those plastics end up in ouroceans. Many marine animals are endangered by this amount of waste bombarding
        their natural habitat because they get entangled in it and eveningest the plastic wastes which leads to injuries
        and death. Not only does plastic harm animals, they also pose great danger to other marine species such as
        coral reefs. The plastics scrape and tear away at the skin of the coral exposing it to infections. Large amounts
        of plastics floating around at the surface of the ocean also blocks sunlight from reaching the corals. This is
        why it is important to us to alter people’s consumption habits and urge them to look for other ways to
        attain certain products. By decreasing our consumption, the demand for production ofcertain plastic based goods
        will decrease which will then cause a decrease in the production of waste.
    </p>

    <h3>Why Rent?</h3>
    <p>
        Have you ever bought an expensive product that has just ended up sitting in storage? Or have you ever needed to
        buy something that you know you are onlygoing to get one or two uses out of and then never need to use it again?
        If you answered yes to either of these questions, then RentReef is the perfect platform that can help you save
        your money while also helping to not feed into overconsumption. As an owner, when you rent something out to 10
        different people, those are 10 products that will not be taken out of a store shelf and 10 less products that
        would have eventually ended up in the trash. As a borrower,you will be able to save tons of money from paying a
        fraction of the price foryour desired amount of uses from a certain product; this will not only save you money
        but also space in your home. You will also have access to products 24/7(depending on the owner) and will not
        have to worry about the constraints ofstore operating hours. When you rent, you not only save money, you
        contribute to save the planet.
    </p>
    </div>
</section>