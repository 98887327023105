import { Validators } from '@angular/forms';

export enum ReviewCategoryType {
  CONDITION = 'CONDITION',
  COMMUNICATION = 'COMMUNICATION',
  VALUE_FOR_MONEY = 'VALUE_FOR_MONEY',
  COMMENT = 'COMMENT'
}

export const GET_REVIEW_CATEGORY_TYPE_STARS = Object.keys(ReviewCategoryType)
  .filter(type => ReviewCategoryType[type] !== ReviewCategoryType.COMMENT)
  .map(type => {
    switch (ReviewCategoryType[type]) {
      case ReviewCategoryType.VALUE_FOR_MONEY:
        return {
          key: 'valueForMoney',
          label: 'Value for Money'
        };
      default:
        const key = ReviewCategoryType[type].toLowerCase();
        return {
          key,
          label: `${key.charAt(0).toUpperCase()}${key.slice(1)}`
        }
    }
  });

const starRatingFormControl = [
  1,
  Validators.compose([
    Validators.required,
    Validators.min(0),
    Validators.max(5)
  ])
];

export const GET_REVIEW_CATEGORY_TYPE_FORMGROUP_SETTINGS = {
  comment: [''],
  communication: starRatingFormControl,
  valueForMoney: starRatingFormControl,
  condition: starRatingFormControl,
};