import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { RentsService } from '../../../infrastructure/core/services/rents.service';

@Injectable({ providedIn: 'root' })
export class BusinessConfigResolver implements Resolve<any> {
    constructor(
        private rentService: RentsService
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.rentService.getBusinessConfig();
    }

}
