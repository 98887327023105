import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { UserReservationRoleType } from '@app/helpers/user-reservation-role-type.enum';
import { ListingRentDetailPair } from '@app/models/listing-rent-detail-pair.model';
import { RentDetail } from '@app/models/rent-detail.model';
// notifier import
import { NotifierService } from 'angular-notifier';
import * as firebase from 'firebase';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Listing } from 'src/app/models/listing.model';
import { ListingListType } from 'src/app/modules/my-listing/pages/my-reservation-page/listing-list/listing-list.component';

interface QueryConfig {
  path: string; //  path to collection
  field: string; // field to orderBy
  limit?: number; // limit per query
  reverse?: boolean; // reverse order?
  prepend?: boolean; // prepend to source?
}

export const DOCUMENT_KEY_RENTS = 'rents';
export const DOCUMENT_KEY_RENT_DETAILS = 'rentDetails';

@Injectable({
  providedIn: 'root'
})
export class ListingService {
  private readonly notifier: NotifierService;
  public isIDValid;
  public listingId;
  public rents;
  public listingDetails$: Observable<any>;
  // Source data
  private _done = new BehaviorSubject(false);
  private _loading = new BehaviorSubject(false);


  // Observable data
  data: Observable<any>;
  done: Observable<boolean> = this._done.asObservable();
  loading: Observable<boolean> = this._loading.asObservable();
  constructor(
    private afs: AngularFirestore,
    private fun: AngularFireFunctions
  ) { }

  getAllList() {
    // , ref => ref.where('status', '==', true).orderBy('createdAt', 'desc')
    return this.afs.collection('listings').snapshotChanges();
  }

  getExploredList() {
    return this.afs.collection('listings', ref => ref.orderBy('createdAt', 'desc').limit(10)).snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data() as Listing;
        data.id = (a.payload.doc as any).id;
        return data;
      });
    }));
  }

  getTopRatedList() {
    return this.afs.collection('listings', ref => ref.limit(10)).snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data() as Listing;
        data.id = (a.payload.doc as any).id;
        return data;
      });
    }));
  }

  getUserList(userID?: string) {
    const uid = userID || JSON.parse(localStorage.getItem('user')).uid;
    return this.afs
      .collection('listings', ref => ref.where('user', '==', uid).orderBy('name', 'asc'))
      .snapshotChanges();
  }

  getListDetails(listingId) {
    return this.afs
      .collection('listings')
      .doc(`${listingId}`)
      .get()
      .pipe(
        map(doc => ({
          id: doc.id,
          ...doc.data()
        }) as Listing)
      );
  }

  getItemDetails(listingId): Observable<any> {
    return this.afs
      .collection('listings')
      .doc(`${listingId}`)
      .valueChanges();
  }

  getIDValid() {
    return this.isIDValid;
  }

  getListingID() {
    return this.listingId;
  }

  getListingDetails(listingID): Observable<Listing> {
    return this.afs
      .collection('listings')
      .doc<Listing>(`${listingID}`)
      .valueChanges()
      .pipe(map(doc => ({
        id: listingID,
        ...doc
      })));
  }

  setListing(listingID) {
    this.listingDetails$ = this.afs
      .collection('listings')
      .doc(`${listingID}`)
      .valueChanges();
  }

  updateListing(listingID, doc) {
    return this.afs
      .collection('listings')
      .doc(`${listingID}`)
      .update(doc);
  }

  setListingId(listingID) {
    this.listingId = listingID;
  }

  setRents(listingId) {
    this.afs
      .collection('rents')
      .doc(`${listingId}`)
      .valueChanges()
      .subscribe(rents => {
        console.log('setRents', rents);
        this.rents = rents;
      });
  }

  // add new rental
  setRent(userId, listingID, doc) {
    this.afs
      .collection('rents')
      .doc(`${listingID}`)
      .collection('rentDetails')
      .add(doc)
      // .set(doc)
      .then(res => console.log('Rental successfully added! listing ID:', res))
      .catch(error => this.notifier.notify('error', error.message));

    this.afs
      .collection('rentals')
      .doc(`${userId}`)
      .collection(`${listingID}`)
      .add(doc)
      .then(res => console.log('Rental successfully added! listing ID:', res))
      .catch(error => this.notifier.notify('error', error.message));
  }

  getRents(listingId) {
    // const rentals2 = this.afs.collectionGroup('${listingId}').get();

    return this.afs
      .collection('rents')
      .doc(`${listingId}`)
      .collection('rentDetails')
      .valueChanges();
  }

  getRentsWithLimit(listingId, limit = 25) {
    // const rentals2 = this.afs.collectionGroup('${listingId}').get();
    return this.afs
      .collection('rents')
      .doc(`${listingId}`)
      .collection('rentDetails', ref => {
        return ref
          .orderBy('endDate', 'desc')
          .limitToLast(limit);
      })
      .valueChanges();
  }

  getRentDetails(listingId: string, rentalId: string): Observable<RentDetail> {
    return this.afs
      .collection(DOCUMENT_KEY_RENTS)
      .doc(listingId)
      .collection(DOCUMENT_KEY_RENT_DETAILS)
      .doc<RentDetail>(rentalId)
      .valueChanges()
      .pipe(
        map(rentDetail => ({
          id: rentalId,
          ...rentDetail
        }))
      );
  }

  getUserReservations(
    reservationType: ListingListType,
    page = 0,
    size = 20,
    userReservationRoleType = UserReservationRoleType.PERSONAL
  ): Observable<ListingRentDetailPair[]> {
    const clientDate = new Date();
    clientDate.setHours(0);
    clientDate.setMinutes(0);
    clientDate.setSeconds(0);
    clientDate.setMilliseconds(0);
    clientDate.setUTCSeconds(0);
    clientDate.setUTCMilliseconds(0);

    return this.fun
      .httpsCallable('getUserReservation')({
        reservationType,
        clientDate: clientDate.toUTCString(),
        pagination: {
          page,
          size
        },
        userReservationRoleType
      })
      .pipe(
        take(1),
        map(pairs => pairs.map(({ listing, rentDetail }) => {
          const parseToTimestamp = (ts) => new firebase.firestore.Timestamp(
            ts._seconds,
            ts._nanoseconds
          );
          const parsedRentDetail = rentDetail.startDate._seconds
            ? {
              ...rentDetail,
              startDate: parseToTimestamp(rentDetail.startDate),
              endDate: parseToTimestamp(rentDetail.endDate),
              transactionDate: rentDetail.transactionDate
                ? parseToTimestamp(rentDetail.transactionDate)
                : undefined,
            }
            : rentDetail;
          return {
            listing,
            rentDetail: parsedRentDetail
          };
        }))
      );
  }

  extractToAndFromDateFromRouteQueryParams(queryParams) {
    let result = {
      initToDate: null,
      initFromDate: null
    };

    const dateToday: Date = new Date();
    const dateTomorrow: Date = new Date();
    dateTomorrow.setDate(dateToday.getDate() + 1);

    result.initFromDate = this.checkDateValidity(queryParams.from) ? new Date(queryParams.from) : dateToday;
    result.initToDate = this.checkDateValidity(queryParams.to) ? new Date(queryParams.to) : dateTomorrow;

    return result;
  }

  private checkDateValidity(date: string): boolean {
    return date && moment(date, 'M-D-YYYY', true).isValid();
  }
}
