import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { ProfileService } from '../auth/profile.service';

@Injectable({
  providedIn: 'root'
})
export class StripeAccountGuard implements CanActivate {
  private readonly notifier: NotifierService;

  constructor(private router: Router, private notifierService: NotifierService, private profileSvc: ProfileService) {
    this.notifier = notifierService;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const stripeAccFlg = localStorage.stripeAccFlg;
    if (!stripeAccFlg || stripeAccFlg !== '201') {
      this.router.navigate(['/profile']);
      this.notifier.notify(
        'error', 'Access Forbidden: Please create Stripe Account first.'
      );
    } else {
      return true;
    }
  }

}
