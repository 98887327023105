import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { HubspotService } from '@app/core/services/hubspot.service';
import { EventTypes } from '@app/helpers/hubspot';
import { IdentityDetails } from '@app/models/hubspot-events';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { FormValidator } from 'src/app/validator/form.validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @Input() redirectTo: string = '';

  registerForm = new FormGroup({
    email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
    firstName: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(45), FormValidator.validName])),
    lastName: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(45), FormValidator.validName]))
  });

  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    private notifier: NotifierService,
    public authService: AuthService,
    private hubspotService: HubspotService
  ) {
  }

  ngOnInit() { }

  registerUser() {
    if (this.registerForm.valid) {
      const email = this.registerForm.value.email;
      const password = this.registerForm.value.password;
      const firstName = this.registerForm.value.firstName;
      const lastName = this.registerForm.value.lastName;

      this.authService
        .signUp({ email, password, firstName, lastName })
        .then(() => {
          this.ngbModal.dismissAll();
          this.redirect();
        })
        .catch(registerError => {
          this.notifier.notify(
            'error',
            'Unable to register user'
          );
          // console.log('err', registerError);
        });
    } else {
      this.ngbModal.dismissAll();
      this.notifier.notify(
        'error',
        'There is an error in registration please fill up all required fields!'
      );
      return;
    }
  }

  dismiss() {
    this.ngbModal.dismissAll();
  }

  loginUser(oAuth?) {
    switch (oAuth) {
      case 'google':
        this.authService
          .googleLogin()
          .then(res => {
            this.authService.updateUserData(res.user, res.additionalUserInfo);
            this.identifyLoginUser({ email: res.user.email });
            this.redirect();
          })
          .catch(err => {
            console.log('err message', err.message);
            console.log('err', err.message);
            this.notifier.notify('error', err.message);
          });
        break;
      case 'facebook':
        this.authService
          .facebookLogin()
          .then(res => {
            this.authService.updateUserData(res.user, res.additionalUserInfo);
            this.identifyLoginUser({ email: res.user.email });
            this.redirect();
          })
          .catch(err => {
            this.notifier.notify('error', err.message);
          });
        break;
    }
  }

  navigateToRoute(route: string) {
    this.dismiss();
    this.router.navigate([route]);
  }

  identifyLoginUser(identityDetails: IdentityDetails) {
    if (identityDetails.email) {
      this.hubspotService.pushEvent(EventTypes.IDENTIFY, identityDetails);
    }
  }

  redirect() {
    if (this.redirectTo && this.redirectTo.trim()) {
      this.navigateToRoute(`${this.redirectTo.trim()}`);
    }
  }

}
