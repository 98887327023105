import { take } from 'rxjs/internal/operators/take';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { User } from '@app/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { auth } from 'firebase/app';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, throttleTime } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private readonly notifier: NotifierService;
  public user: Observable<User | any>;
  public currentUser: User;
  unsubscribe$ = new Subject();
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    public ngZone: NgZone,
    private ngbModal: NgbModal,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
    this.user = this.afAuth.authState
      .pipe(
        switchMap(user => {
          return user
            ? this.afs.doc<User>(`users/${user.uid}`).valueChanges()
            : of(null);
        })
      )
      .pipe(throttleTime(1000));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  facebookLogin() {
    const provider = new auth.FacebookAuthProvider();
    return this.afAuth
      .signInWithPopup(provider);
  }

  googleLogin() {
    const provider = new auth.GoogleAuthProvider();
    return this.afAuth
      .signInWithPopup(provider);
  }

  updateUserData(user, userData?, registration = false) {
    // Sets user data to firestore on login
    this.ngbModal.dismissAll();
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    return userRef.valueChanges().pipe(
      registration ? take(1) : takeUntil(this.unsubscribe$),
      throttleTime(registration ? 0 : 5000)
    ).subscribe(storedData => {
      let photo;
      let name;
      let firstname;
      let lastname;
      let onboarding;
      if (storedData) {
        photo = storedData.photoURL;
        name = storedData.displayName;
        firstname = storedData.firstName;
        lastname = storedData.lastName;
        onboarding = storedData.onboarding;
      } else {
        photo = user.photoURL;
        name = user.displayName || `${user.firstName || ''}${user.lastName ? ' ' : ''}${user.lastName || ''}`;
        onboarding = true;
        if (registration && userData) {
          firstname = userData.firstName;
          lastname = userData.lastName;
          photo = user.photoURL;
        } else {
          firstname = userData.profile.given_name;
          lastname = userData.profile.family_name;
        }
      }
      const data: User = {
        uid: user.uid,
        email: user.email,
        photoURL: photo,
        displayName: name,
        firstName: firstname || null,
        lastName: lastname || null,
        onboarding
      };

      this.user.pipe(takeUntil(this.unsubscribe$)).subscribe(user => {
        if (user && user.stripe_account_ID) {
          localStorage.setItem('stripeAccFlg', '201');
        }
      });

      localStorage.setItem('user', JSON.stringify(data));
      // this.ngbModal.dismissAll();

      return userRef.set(data, { merge: true });
    });
  }

  signOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.clear();
      this.router.navigate(['/']);
      this.unsubscribe$.next(true);
    });
  }

  // Sign up with email/password
  signUp(userData) {
    return this.afAuth
      .createUserWithEmailAndPassword(userData.email, userData.password)
      .then(result => {
        this.signIn(userData, true);
      })
      .catch(error => {
        this.notifier.notify('error', error.message);
      });
  }

  // Sign in with email/password
  signIn(userData, registration?) {
    return this.afAuth
      .signInWithEmailAndPassword(userData.email, userData.password)
      .then(result => {
        this.updateUserData(result.user, userData, true);
        if (result.user.emailVerified !== true) {
          this.sendVerificationMail();
          this.notifier.notify(
            'warning',
            'Please validate your email address to be able to rent and add a listing. Kindly check your inbox.'
          );
        } else {
          this.ngZone.run(() => {
            this.router.navigate(['/']);
          });
        }
      })
      .catch(error => {
        this.notifier.notify('error', error.message);
      });
  }

  // Send email verfificaiton when new user sign up
  sendVerificationMail() {
    return this.afAuth.currentUser.then(async (res) => {
      return await res.sendEmailVerification({
        url: `${window.location.origin}/verify-email?email=${res.email}`
      });
    });
  }

  // Send password reset email
  sendPasswordResetEmail(email) {
    return this.afAuth.sendPasswordResetEmail(email).then(() => {
      // console.log(`Password reset email sent to ${email}`)
      return `Password reset email sent to ${email}`;
    }).catch((error) => {
      console.log(error.message)
      return error.message;
    });
  }
}
