import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class SaveFormService {
  constructor() { }

  curateFormData(formData) {
    return {
      address: formData.address,
      category: formData.category,
      currency: formData.currency,
      description: formData.description,
      location: formData.location,
      monthlyDiscount: formData.monthlyDiscount,
      name: formData.name,
      rentalPrice: formData.rentalPrice,
      weeklyDiscount: formData.weeklyDiscount,
      status: true,
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      startDate: new Date(formData.startDate),
      endDate: formData.endDate ? new Date(formData.endDate) : null,
      images: formData.images
    };
  }
  curateMessageRoomData(receiverData, initiatorData) {
    // todo update code here to count unreads
    const timestampToday = firebase.firestore.Timestamp.fromDate(new Date());
    return {
      participants: [receiverData.uid, initiatorData.uid],
      receiverUserUnreads: 0,
      receiverUserId: receiverData.uid,
      receiverUserName: receiverData.firstName,
      receiverUserProfilePic: receiverData.photoURL,
      receiverLastSeen: timestampToday,
      initiatorUserUnreads: 0,
      initiatorUserId: initiatorData.uid,
      initiatorUserName: initiatorData.firstName,
      initiatorUserProfilePic: initiatorData.photoURL,
      initiatorLastSeen: timestampToday,
      lastMessageUserId: initiatorData.uid,
      lastMessage: '',
      createdAt: timestampToday,
      lastUpdatedAt: timestampToday,
      chats: false
    };
  }
}
