import { NotifierService } from 'angular-notifier';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { User } from '@app/models/user.model';
import { AuthService } from './auth.service';
import { take, map, debounceTime, throttleTime, tap } from 'rxjs/operators';
import * as firebase from 'firebase';
import { DateUtil } from '@app/shared/utils/date-util.service';
import { UserReservationRoleType } from '@app/helpers/user-reservation-role-type.enum';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private dateUtil: DateUtil,
    private notifier: NotifierService
  ) { }

  getUserDetails(userID: string): Observable<User> {
    return this.afs
      .collection('users')
      .doc<User>(`${userID}`)
      .valueChanges();
  }

  updateUserActivity(throttleTime = 2000): Observable<any> {
    return this.authService
      .user
      .pipe(
        take(1),
        debounceTime(throttleTime * 3),
        tap(user => {
          if (user && user.uid) {
            const lastActive = firebase.firestore.Timestamp.fromDate(new Date());
            this.afs
              .collection('users')
              .doc<User>(`${user.uid}`)
              .update({ lastActive });
          }
        })
      );
  }

  updateDefaultUserReservationRole(userReservationRoleType: UserReservationRoleType): void {
    this.authService
      .user
      .pipe(take(1))
      .subscribe((user: User) => {
        if (user && user.uid) {
          this.afs
            .collection('users')
            .doc<User>(`${user.uid}`)
            .update({ userReservationRoleType })
            .catch(_ => this.notifier.notify('error', 'An error occured when updating user.'));
        }
      });
  }

  getUserLastActiveString(userID: string): Observable<string> {
    return this.afs
      .collection('users')
      .doc<User>(`${userID}`)
      .valueChanges()
      .pipe(
        map(user => {
          if (user.lastActive) {
            const lastActiveDate = user.lastActive.toDate();

            return this.dateUtil.getDifferenceUiString(new Date(), lastActiveDate);
          }
          return '';
        })
      );
  }
}
