<main role="main">
    <!-- WELCOME BANNER -->
    <div class="col-md-12 col-lg-12 no-padding _57Whr mx-4 mb-4" style="margin-top: 100px;"
    *ngIf="(authService.user | async) as user">
        <div class="card-banner d-none d-sm-block">
            <label class="p-4">
                <!-- <svg class="feather banner-icon">
                    <use xlink:href="/assets/img/feather-sprite.svg#info" />
                </svg> -->
                <strong>Hi {{ user.firstName }}!</strong><br>
                Checkout our <a routerLink="/listing">marketplace</a> and what we have to offer or <a routerLink="/my-listing/me">create your own listing</a> and start earning.
            </label>
            <!-- <img src="/assets/images/see-more.svg" class="img-look" /> -->
            <!-- <div class="divider"></div>
            <img class="_59Wdnd" src="/assets/images/triple-chevron-down.svg" /> -->
        </div>
    </div>
    <div class="jumbotron py-5 mb-3" *ngIf="!ifLogged">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1 class="display-3 landing-main-title mt-5" style="font-weight: 100;">
                        Looking for a <span style="
                        color: #f91988;
                        font-family: 'Circular';
                        font-weight: 900;">{{listingItem}}?</span>
                    </h1>
                    <div id="output"></div>
                    <app-custom-search (categorySelectedOutput)="onCategorySelected($event)"
                        (removeSelectedOutput)="onCategoryRemove($event)" (search)="searchListing($event)">
                    </app-custom-search>
                    <label class="tip-div" style="font-size: 20px;">...or be <a
                            style="color: var(--secondary_color);font-weight: 500;">our partner</a> and start
                        earning</label>
                </div>
                <div class="col d-none d-md-block align-self-end">
                    <img class="img-fluid" src="/assets/images/all-items.png" />
                </div>
            </div>
            <!-- <img _ngcontent-iwd-c3="" class="landing-cover landing-cover-secondary" src="/assets/images/circle-bg.png"> -->
            <!-- <img src="/assets/images/hands-3d.png" class="landing-cover" /> -->
        </div>
    </div>
    <div class="container-fluid">
        <!-- <img *ngIf="!ifLogged" src="/assets/images/wave.svg" alt="wave" class="wave-img d-none d-md-block" /> -->
        <div class="row listing_row">
            <!-- EXPLORE RENTREEF -->
            <div class="col-md-12 col-lg-12 no-padding explore-part">
                <h3 class="align_left">Explore RentReef</h3>
                <div class="_42Wrnd">
                    <label>This week’s latest items</label>
                    <a routerLink="/listing">See All</a>
                </div>
                <section class="list-part" *ngIf="exploredList">
                    <swiper [slidesPerView]="4" [spaceBetween]="5" [pagination]="true" [preloadImages]
                        [centeredSlides]="true" [centerInsufficientSlides]="true" [loop]="true"
                        [centeredSlidesBounds]="true">
                        <ng-container *ngFor="let list of exploredList">
                            <ng-template swiperSlide>
                                <div class="listing" (click)="goToDetails(list.id || list.objectID)">
                                    <div>
                                        <img class="listing-item" appLazyload
                                            [src]="list.images ? ((list.images[0] | imageUrl) | async) : ''">
                                    </div>
                                    <label class="title">{{ list.name ? list.name : 'No Title' }}</label>
                                    <label class="price">S{{ list.rentalPrice | mcp }} /day</label>
                                    <div *ngIf="list.averageRating" class="rating">
                                        <app-rating-stars-label [rating]="list.averageRating">
                                        </app-rating-stars-label>
                                    </div>
                                    <label class="location pin">
                                        <img src="./assets/images/map-pin.svg" />
                                        {{ list.location ? list.location : 'No Location specified' }}
                                    </label>
                                </div>
                            </ng-template>
                        </ng-container>
                    </swiper>
                </section>
            </div>
            <!-- TOP RATED -->
            <div class="col-md-12 col-lg-12 mt-4 no-padding explore-part">
                <h3 class="align_left">Top Rated</h3>
                <div class="_42Wrnd">
                    <label>Our highest rated and most popular listings</label>
                    <a routerLink="/listing">See All</a>
                </div>
                <section class="list-part" *ngIf="topRatedList">
                    <swiper [slidesPerView]="4" [spaceBetween]="5" [pagination]="true" [preloadImages]
                        [centeredSlides]="true" [centerInsufficientSlides]="true" [loop]="true"
                        [centeredSlidesBounds]="true">
                        <ng-container *ngFor="let list of topRatedList">
                            <ng-template swiperSlide>
                                <div class="listing" (click)="goToDetails(list.id || list.objectID)">
                                    <div>
                                        <img class="listing-item" appLazyload
                                            [src]="list.images ? ((list.images[0] | imageUrl) | async) : ''">
                                    </div>
                                    <label class="title">{{ list.name ? list.name : 'No Title' }}</label>
                                    <label class="price">S{{ list.rentalPrice | mcp }} /day</label>
                                    <div *ngIf="list.averageRating" class="rating">
                                        <app-rating-stars-label [rating]="list.averageRating">
                                        </app-rating-stars-label>
                                    </div>
                                    <label class="location pin">
                                        <img src="./assets/images/map-pin.svg" />
                                        {{ list.location ? list.location : 'No Location specified' }}
                                    </label>
                                </div>
                            </ng-template>
                        </ng-container>
                    </swiper>
                </section>
            </div>
            <!-- HOW IT WORKS -->
            <div class="col-md-12 col-lg-12 no-padding mt-4" *ngIf="!ifLogged">
                <h3 class="align_center">How it works</h3>
                <br>
                <br>
            </div>
            <div class="col-md-12 col-lg-12 no-padding align_center" *ngIf="!ifLogged">
                <img src="/assets/images/process.png" class="process" />

                <div class="col-md-2 _00ab00 no-padding">
                    <div class="_00ab01 align_center">
                        <div class="_00aa00 _00aa01">
                        </div>
                        <label>Search</label>
                    </div>
                    <img src="/assets/images/curve-dots.svg" />
                </div>
                <div class="col-md-2 _00ab00 no-padding">
                    <div class="_00ab01 align_center">
                        <div class="_00aa00 _00aa02">
                        </div>
                        <label>Book</label>
                    </div>
                    <img src="/assets/images/curve-dots-alt.svg" />
                </div>
                <div class="col-md-2 _00ab00 no-padding">
                    <div class="_00ab01 align_center">
                        <div class="_00aa00 _00aa03">
                        </div>
                        <label>Chat</label>
                    </div>
                    <img src="/assets/images/curve-dots.svg" />
                </div>
                <div class="col-md-2 _00ab00 no-padding">
                    <div class="_00ab01 align_center">
                        <div class="_00aa00 _00aa04">
                        </div>
                        <label>Collect</label>
                    </div>
                    <img src="/assets/images/curve-dots-alt.svg" />
                </div>
                <div class="col-md-2 _00ab00 no-padding">
                    <div class="_00ab01 align_center">
                        <div class="_00aa00 _00aa05">
                        </div>
                        <label>Enjoy</label>
                    </div>
                    <img src="/assets/images/curve-dots.svg" />
                </div>
                <div class="col-md-2 _00ab00 no-padding">
                    <div class="_00ab01 align_center">
                        <div class="_00aa00 _00aa06">
                        </div>
                        <label>Return</label>
                    </div>
                </div>
            </div>
            <!--  SMALLER FOOTPRINT  - HIDDEN-->
            <div class="col-md-7 col-lg-7 no-padding _DHewjw" *ngIf="false">
                <h2 class="align_left">Smaller Footprint</h2>
                <label class="_23duec align_left">Renting means you are reusing what is already available, thus
                    less production waste and a smaller carbon footprint. Not only is renting cheaper,
                    but you are also contributing to the well-being
                    of Mother Earth.</label>
            </div>
            <div class="col-md-5 col-lg-5 no-padding _DHewjw hidden" *ngIf="false">
                <img src="/assets/images/earth-3d.svg" class="img-earth" />
            </div>
            
            <div class="col-md-12 col-lg-12 no-padding hidden">
                <br><br><br><br><br><br>
                <div class="_62hRwh">
                    <h2>What they say...</h2>
                    <div class="_tywb23">
                        <img src="/assets/images/client.svg">
                    </div>
                    <div class="_2dWrbf">
                        <label>John Middleman</label>
                        <label class="_fbe24b">Brisbane, Australia</label>
                        <label class="_2u3Gsj">
                            <mat-progress-bar class="rating-bar" mode="determinate" value="40"></mat-progress-bar>
                            <p>7/10</p>
                        </label>
                        <span>Brilliant guy and very easy to deal with.
                            Great kit and really easy to collect and return
                            equipment.Excellent communication, absolutely lovely people, amazing gimbal and an
                            incredibly smooth
                            process!
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container _3bfRt"></div>
    <div class="container _97dbGw">
        <div class="col-md-12 col-lg-12 no-padding align_center">
            <label><a class="shimmer" routerLink="/listing">EXPLORE MARKETPLACE</a></label>
        </div>
    </div>
</main>