import { Injectable } from '@angular/core';
import { ChatMessage } from '@app/models/chat-message';
import { User } from '@app/models/user.model';
import * as firebase from 'firebase';

@Injectable()
export class MessageUtil {

  constructor() { }

  createNewMessage(message: string = '', user: User): ChatMessage {
    if (message.trim()) {
      return {
        time: firebase.firestore.Timestamp.fromDate(new Date()),
        userId: user.uid,
        userName: user.firstName || user.displayName,
        unread: true,

        message
      }
    }
  }
}
