<div class="search-wrapper" [hidden]="isNav">
  <!-- <i class="fa fa-search search-icon"></i> -->
  <svg class="feather search-icon">
    <use xlink:href="../../../../assets/img/feather-sprite.svg#search" />
  </svg>
  <ul class="chosen-choices" style="z-index: 1;" (click)="triggerList = true; focusInput()">
    <!-- <li class="search-choice" *ngIf="inputSelected">
      <span>{{ inputSelected }}</span>
      <svg class="feather pill-remove-icon" aria-hidden="true" (click)="onRemoveInput()">
        <use xlink:href="../../../../assets/img/feather-sprite.svg#x" />
      </svg>
    </li> -->
    <!-- <li class="search-field" [ngClass]="inputSelected ? 'invisible' : 'visible'"> -->
    <input type="search" class="input-search" placeholder="{{ searchPlaceholder }}" [(ngModel)]="inputSelected"
      (keyup.enter)="inputEntered()" #searchInput />
    <!-- </li> -->
    <button (click)="search.emit(true)" type="submit" class="search-btn">
      Search
    </button>
  </ul>
  <app-custom-typehead [inputSearched]="inputSearched" [isSearching]="isSearching" [trigger]="triggerList"
    [categorySelectedInput]="categorySelected" (triggerFalse)="onTriggerFalse($event)"
    (categorySelected)="onCategorySelected($event)" (checkSearch)="checkSearch($event)"></app-custom-typehead>
</div>
<div class="search-wrapper _Fjuddjw" [hidden]="!isNav">
  <svg class="feather search-icon" (click)="inputEntered()">
    <use xlink:href="../../../../assets/img/feather-sprite.svg#search" />
  </svg>
  <input type="text" placeholder="What are you looking for?" class="nav-search" [(ngModel)]="inputSelected"
    (keyup.enter)="inputEntered()" (click)="triggerList = true; focusInput()" #searchInputNav />
  <app-custom-typehead [inputSearched]="inputSearched" [isSearching]="isSearching" [trigger]="triggerList"
    [categorySelectedInput]="categorySelected" (triggerFalse)="onTriggerFalse($event)"
    (categorySelected)="onCategorySelected($event)" (checkSearch)="checkSearch($event)"></app-custom-typehead>
</div>