export interface Category {
  value: string;
  viewValue: string;
}

export interface CategoryGroup {
  disabled?: boolean;
  name: string;
  category: Category[];
}

export const category = {
  Cars: 'l_vehicles',
  'Car Accessories': 'l_vehicles',
  Motorbikes: 'l_vehicles',
  'Bicycles & PMDs': 'l_vehicles',
  'Learning & Enrichment': 'l_hobbies',
  Photography: 'l_hobbies',
  'Design & Craft': 'l_hobbies',
  'Music & Media': 'l_hobbies',
  'Food & Drinks': 'l_hobbies',
  'Toys & Games': 'l_hobbies',
  'Vintage & Collectibles': 'l_hobbies',
  Travel: 'l_hobbies',
  Sports: 'l_hobbies',
  Entertainment: 'l_hobbies',
  'Men\'s Fashion': 'l_fashion',
  'Women\'s Fashion': 'l_fashion',
  'Health & Beauty': 'l_fashion',
  Furniture: 'l_home',
  Gardening: 'l_home',
  'Home Appliances': 'l_home',
  'Babies & Kids': 'l_home',
  'Assistive Devices': 'l_home',
  'Books & Stationery': 'l_home',
  Electronics: 'l_electronics',
  Computers: 'l_electronics',
  'Mobile Phones & Tablets': 'l_electronics'
};

export const categories: CategoryGroup[] = [
  {
    name: 'Vehicles',
    category: [
      { value: 'Cars', viewValue: 'Cars' },
      { value: 'Car Accessories', viewValue: 'Car Accessories' },
      { value: 'Motorbikes', viewValue: 'Motorbikes' },
      { value: 'Bicycles & PMDs', viewValue: 'Bicycles & PMDs' }
    ]
  },
  {
    name: 'Hobbies & Games',
    category: [
      { value: 'Learning & Enrichment', viewValue: 'Learning & Enrichment' },
      { value: 'Photography', viewValue: 'Photography' },
      { value: 'Design & Craft', viewValue: 'Design & Craft' },
      { value: 'Food & Drinks', viewValue: 'Food & Drinks' },
      { value: 'Music & Media', viewValue: 'Music & Media' },
      { value: 'Toys & Games', viewValue: 'Toys & Games' },
      { value: 'Vintage & Collectibles', viewValue: 'Vintage & Collectibles' },
      { value: 'Travel', viewValue: 'Travel' },
      { value: 'Sports', viewValue: 'Sports' },
      { value: 'Entertainment', viewValue: 'Entertainment' }
    ]
  },
  {
    name: 'Fashion',
    category: [
      { value: 'Men\'s Fashion', viewValue: 'Men\'s Fashion' },
      { value: 'Women\'s Fashion', viewValue: 'Women\'s Fashion' },
      { value: 'Health & Beauty', viewValue: 'Health & Beauty' }
    ]
  },
  {
    name: 'Home & Living',
    category: [
      { value: 'Furniture', viewValue: 'Furniture' },
      { value: 'Gardening', viewValue: 'Gardening' },
      { value: 'Home Appliances', viewValue: 'Home Appliances' },
      { value: 'Babies & Kids', viewValue: 'Babies & Kids' },
      { value: 'Assistive Devices', viewValue: 'Assistive Devices' },
      { value: 'Books & Stationery', viewValue: 'Books & Stationery' }
    ]
  },
  {
    name: 'Electronics & Mobiles',
    category: [
      { value: 'Electronics', viewValue: 'Electronics' },
      { value: 'Computers', viewValue: 'Computers' },
      { value: 'Mobile Phones & Tablets', viewValue: 'Mobile Phones & Tablets' }
    ]
  }
];
