import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { AppNotificationService } from '@app/core/services/app-notification.service';
import { AppNotificationAction } from '@app/helpers/app-notification-action.enum';
import { AppNotification } from '@app/models/app-notification.model';
import { User } from '@app/models/user.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notification-list-item',
  templateUrl: './notification-list-item.component.html',
  styleUrls: ['./notification-list-item.component.scss']
})
export class NotificationListItemComponent implements OnInit {

  @Input() appNotification: AppNotification = null;
  @Output() notificationAction = new EventEmitter<AppNotificationAction>();
  user$: Observable<User>;

  constructor(
    private appNotificationService: AppNotificationService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.user$ = this.authService.user;
  }

  clickMarkAsRead(event: Event) {
    event.stopPropagation();
    if (this.appNotification) {
      this.appNotificationService.markAsRead(
        this.appNotification.id,
        !this.appNotification.markedAsRead
      );
    }
    // menuTrigger.closeMenu();
  }

  executeAction() {
    this.notificationAction.emit(this.appNotification.action);
  }

}
