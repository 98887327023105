import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AppNotification } from '@app/models/app-notification.model';
import * as firebase from 'firebase';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { NOTIFICATIONS, USERS } from './../../../helpers/firestore.keys';
import { AuthService } from './../auth/auth.service';

const CREATED_ON = 'createdOn';
const UNREAD = 'unread';

@Injectable({
  providedIn: 'root'
})
export class AppNotificationService {

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService
  ) { }

  getUserNotifications(): Observable<AppNotification[]> {
    // ADD PAGINATION, ONLY GET LAST 10
    return this.authService.user
      .pipe(
        mergeMap(user => !!user && user.uid
          ? this.afs.doc(`${USERS}/${user.uid}`)
            .collection(
              NOTIFICATIONS,
              ref => ref.orderBy(CREATED_ON, 'desc').limit(10)
            )
            .snapshotChanges()
            .pipe(
              map(doc => doc.map(item => ({
                id: (item.payload.doc as any).id,
                ...item.payload.doc.data()
              }) as AppNotification))
            )
          : of([])
        )
      );
  }

  getUserUnreadNotificationsCount(): Observable<number> {
    return this.authService.user
      .pipe(
        mergeMap(user => !!user && user.uid
          ? this.afs.doc(`${USERS}/${user.uid}`)
            .collection(NOTIFICATIONS, ref => ref.where(UNREAD, '==', true))
            .snapshotChanges()
            .pipe(
              map(doc => doc.length)
            )
          : of(0)
        )
      );
  }

  markAsRead(notificationId: string, toggle = true): void {
    let payload: any = {
      markedAsRead: toggle,
      unread: !toggle,
      dateMarkedAsRead: firebase.firestore.Timestamp.fromDate(new Date())
    };
    this.authService.user
      .pipe(
        tap(user => {
          if (!!user && user.uid) {
            this.afs.doc(`${USERS}/${user.uid}`)
              .collection(NOTIFICATIONS)
              .doc(notificationId)
              .update({ ...payload });
          }
        }),
        take(1)
      ).subscribe();
  }

}
