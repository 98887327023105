<section class="public-pages">
    <div class="container-sm cvjEh terms-page">
        <h2><strong>RentReef.</strong></h2>
        <h2><strong>Website Terms of Use</strong></h2>
        <b>Last Modified {{ lastUpdated | date: 'MMMM d, y' }}</b>
        <hr />
        <h4><strong>1. Introduction</strong></h4>
        <p><span style="font-weight: 400;">Welcome to the Website of RentReef (&ldquo;RentReef,&rdquo; &ldquo;we,&rdquo;
                &ldquo;our,&rdquo; or &ldquo;us&rdquo;). This Website Terms of Use (the &ldquo;Website Terms&rdquo; or
                &ldquo;Terms of Use&rdquo;) is a legally binding agreement between you (&ldquo;Users,&rdquo;
                &ldquo;Customers,&rdquo; and/or &ldquo;Website Visitors&rdquo;) and RentReef.</span></p>
        <p><span style="font-weight: 400;">This Website Terms governs your use of our website i.e., located at </span><a
                href="https://rentreef.com/"><span style="font-weight: 400;">www.rentreef.com</span></a><span
                style="font-weight: 400;"> (the &ldquo;Website&rdquo; or &ldquo;Site&rdquo;). By using or accessing the
                Website, you agree to be bound by, and use our Site in compliance with, these Website Terms.</span></p>
        <p><span style="font-weight: 400;">IF YOU DO NOT AGREE TO THESE WEBSITE TERMS, PLEASE DO NOT USE OUR SITE AND
                FEEL FREE TO CONTACT US AT </span><span style="font-weight: 400;">LEGAL@rentreef.com</span><span
                style="font-weight: 400;">.</span></p>
        <p><span style="font-weight: 400;">These Website Terms apply to the use of the Site. PLEASE READ THESE WEBSITE
                TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THE WEBSITE TERMS CONTAIN A
            </span><strong>BINDING AND MANDATORY CUSTOMER ARBITRATION</strong><span style="font-weight: 400;"> AND
            </span><strong>CLASS ACTION/JURY TRIAL WAIVER PROVISION </strong><span style="font-weight: 400;">THAT
                REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR
                CLASS ACTIONS AND LIMITS THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF CERTAIN DISPUTES.</span></p>
        <p><span style="font-weight: 400;">ANY DISPUTES RELATING TO THE AGREEMENT, YOUR ACCOUNT OR THE SERVICES PROVIDED
                BY US MUST BE RESOLVED BY BINDING ARBITRATION AND ON AN INDIVIDUAL BASIS. PLEASE SEE SECTION 19 FOR
                FURTHER DETAILS.</span></p>
        <p><span style="font-weight: 400;">These Website Terms govern your use and access of our Site. To register for a
                RentReef account and to be eligible to use our Services (as defined under the </span><a
                href="https://heap.io/tos"><span style="font-weight: 400;">Terms of Service</span></a><span
                style="font-weight: 400;">), you must thoroughly review and accept RentReefs&rsquo; </span><a
                href="https://heap.io/tos"><span style="font-weight: 400;">TERMS OF SERVICE</span></a><span
                style="font-weight: 400;">. IF YOU DO NOT AGREE WITH THE TERMS OF SERVICE, PLEASE DO NOT CLICK THE
                &ldquo;I ACCEPT&rdquo; BUTTON AND PLEASE DO NOT USE OUR SERVICES.</span></p>
        <p><span style="font-weight: 400;">Capitalized terms not described herein shall have the same meaning as
                ascribed to them under the </span><a href="https://heap.io/tos"><span style="font-weight: 400;">Terms of
                    Service</span></a><span style="font-weight: 400;"> and/or the </span><a
                href="https://heap.io/privacy"><span style="font-weight: 400;">Privacy Policy</span></a><span
                style="font-weight: 400;">.</span></p>
        <h4><strong>2. RentReef Agreements</strong></h4>
        <p><span style="font-weight: 400;">This Website Terms only governs your use and access rights to our Website.
                Please note that if you purchase and use our Services,&nbsp; you will be subject to our </span><a
                href="https://heap.io/tos"><span style="font-weight: 400;">Terms of Service</span></a><span
                style="font-weight: 400;"> in addition to being subject to these Website Terms.</span></p>
        <p><span style="font-weight: 400;">Your use and access of our Website and Services are also subject to our
            </span><a href="https://heap.io/privacy"><span style="font-weight: 400;">Privacy Policy</span></a><span
                style="font-weight: 400;">. Please read our Privacy Policy carefully to understand your rights and
                obligations with respect to collection and storage of data.</span></p>
        <p><span style="font-weight: 400;">The Website Terms, the Terms of Service and the Privacy Policy is hereinafter
                collectively referred to as &ldquo;RentReef Agreements.&rdquo; In the event of any conflict between the
                above-mentioned agreements, the Privacy Policy will take precedence over the Terms of Service and the
                Website Terms.</span></p>
        <p><span style="font-weight: 400;">IF YOU WANT TO KNOW MORE ABOUT RentReef AGREEMENTS, PLEASE SEND US YOUR QUERY
                AT </span><span style="font-weight: 400;">LEGAL@RentReef.com</span><span
                style="font-weight: 400;">.</span></p>
        <h4><strong>3. License to use Our Website</strong></h4>
        <p><span style="font-weight: 400;">Subject to these Website Terms, we grant to you a limited, non-exclusive,
                non-transferable, non-sublicensable, right to access and use our Site for your internal use and not for
                resale or further distribution. Your right to use our Site is limited by all terms and conditions set
                forth in these Website Terms.</span></p>
        <p><span style="font-weight: 400;">Except for your pre-existing rights and this license granted to you, we and
                our licensors retain all right, title and interest in and to our Site, including all related
                intellectual property rights. Our Site is protected by applicable intellectual property laws, including
                United States copyright law and international treaties.</span></p>
        <p><span style="font-weight: 400;">Except as otherwise explicitly provided in these Website Terms or as may be
                expressly permitted by applicable law, you will not, and will not permit or authorize any third party
                to: (i) reproduce, modify, translate, enhance, decompile, disassemble, reverse engineer or create
                derivative works of our Site; (ii) rent, lease or sublicense access to any of our Site; or (iii)
                circumvent or disable any security or technological features or measures of our Site. Any breach of this
                Section 3 will be considered a material breach of this agreement.</span></p>
        <h4><strong>4. Restrictions</strong></h4>
        <p><span style="font-weight: 400;">You must comply with all applicable laws when using our Site. Except as may
                be expressly permitted by applicable law, or as RentReef may authorize expressly in writing, you will
                not, and will not permit anyone else to: (i) store, copy, modify, distribute, or resell any of the
                information; audio, visual, and audiovisual works, or other content made available on our Site
                (collectively, &ldquo;Site Content&rdquo;) or compile or collect any Site Content as part of a database
                or other work; (ii) use any automated tool (e.g., robots, spiders) to access or use our Site or to
                store, copy, modify, distribute, or resell any Site Content; (iii) rent, lease, or sublicense your
                access to our Site to another person; (iv) use any Site or Site Content for any purpose except for your
                own internal use; (v) circumvent or disable any digital rights management, usage rules, or other
                security features of our Site; (vi) use our Site in a manner that overburdens, or that threatens the
                integrity, performance, or availability of, our Site; or (vii) remove, alter, or obscure any proprietary
                notices (including copyright and trademark notices) on any portion of our Site or any Site
                Content.</span></p>
        <h4><strong>5. Restricted Areas of the Site</strong></h4>
        <p><span style="font-weight: 400;">Certain parts of our Site may be password-restricted to registered users or
                other authorized persons (&ldquo;Password-Protected Areas&rdquo;). If you are authorized to gain access
                to any Password-Protected Areas, you agree that you are entirely responsible for maintaining the
                confidentiality of your password, and agree to notify us if the password is lost, stolen, disclosed to
                an unauthorized third party, or otherwise may have been compromised. You agree that you are entirely
                responsible for any and all activities that occur under your account, whether or not you are the
                individual who undertakes such activities. You agree to immediately notify us of any unauthorized use of
                your account or any other breach of security in relation to your password or our Site that is known to
                you.</span></p>
        <h4><strong>6. RentReef Marketing Emails</strong></h4>
        <p><span style="font-weight: 400;">We may use the email address so provided by you to send you promotional
                materials or to send you updated information on our latest products and Services. If you want to
                unsubscribe from receiving any promotional materials from our end, please click on the
                &ldquo;Unsubscribe Option&rdquo; available at the bottom of the email.</span></p>
        <h4><strong>7. Modifications</strong></h4>
        <p><span style="font-weight: 400;">RentReef reserves the right, at its sole discretion, to modify or replace any
                part of this Website Terms. We will inform you about any modifications to these Website Terms or to any
                of our other agreements by email, website pop-up messages, or other reasonable means within 15 days of
                such modifications. Additionally, it is also your responsibility to check these Website Terms
                periodically for changes. Your continued use of or access to the Website following the posting of any
                changes to the Website Terms constitutes acceptance of those changes. RentReef, Inc. may also, in the
                future, offer new Site and/or features through the Website (including, the release of new tools and
                resources). Such new features and/or Site shall be subject to the terms and conditions of this Website
                Terms and other related terms as may be applicable.</span></p>
        <h4><strong>8. Privacy Policy</strong></h4>
        <p><span style="font-weight: 400;">Our Privacy Policy (&ldquo;Privacy Policy&rdquo;) describes how we handle
                information provided to us by our customers and users when using and accessing our Website and Services.
                You understand that when you access and/or use of our Website and Services, you consent to our </span><a
                href="https://heap.io/privacy"><span style="font-weight: 400;">PRIVACY POLICY</span></a><span
                style="font-weight: 400;">. Additionally, you also agree to the transfer of information to the United
                States of America (the &ldquo;USA&rdquo;).</span></p>
        <p><span style="font-weight: 400;">YOU AGREE TO COMPLY WITH ALL APPLICABLE PRIVACY AND DATA PROTECTION
                REGULATIONS. FURTHER, YOU AGREE TO NOT USE OUR SERVICES TO SEND US SENSITIVE INFORMATION WHERE
                UNAUTHORIZED DISCLOSURE COULD CAUSE MATERIAL, SEVERE, OR CATASTROPHIC HARM OR IMPACT TO RentReef, ANY
                DATA SUBJECTS OR RELYING PARTIES. PLEASE NOTE THAT SENSITIVE INFORMATION INCLUDES:</span></p>
        <p>&nbsp;</p>
        <ul>
            <li><strong><strong>Passwords, authentication/authorization credentials</strong></strong></li>
        </ul>
        <ul>
            <li><strong>Information under strict regulatory or contractual handling requirements (e.g., PCI, HIPAA, and
                    state and federal data security laws) including:</strong></li>
        </ul>
        <p>&nbsp;</p>
        <ol>
            <ol>
                <ul>
                    <li style="font-weight: 400;"><span style="font-weight: 400;">Credit Card Information including
                            credit card numbers, CIV numbers (three digit codes for Visa and MasterCard, four digit code
                            for American Express) and magnetic stripe information</span></li>
                    <li style="font-weight: 400;"><span style="font-weight: 400;">Social Security Numbers</span></li>
                    <li style="font-weight: 400;"><span style="font-weight: 400;">Drivers License Numbers</span></li>
                    <li style="font-weight: 400;"><span style="font-weight: 400;">Passport Numbers</span></li>
                    <li style="font-weight: 400;"><span style="font-weight: 400;">Government Issued Identification
                            Numbers</span></li>
                    <li style="font-weight: 400;"><span style="font-weight: 400;">Financial Account Information</span>
                    </li>
                    <li style="font-weight: 400;"><span style="font-weight: 400;">Health data</span></li>
                    <li style="font-weight: 400;"><span style="font-weight: 400;">Biometric data</span></li>
                    <li style="font-weight: 400;"><span style="font-weight: 400;">Personally identifiable information
                            knowingly collected from children under the age of 13 or from online Site directed toward
                            children</span></li>
                    <li style="font-weight: 400;"><span style="font-weight: 400;">Real time geolocation data which can
                            identify an individual</span></li>
                </ul>
            </ol>
        </ol>
        <p>&nbsp;</p>
        <ul>
            <li><strong><strong>Business secrets deemed highly confidential (e.g., highly-confidential business
                        strategies and communications, sensitive attorney-client privileged and confidential
                        communications).</strong></strong></li>
        </ul>
        <p>&nbsp;</p>
        <p><span style="font-weight: 400;">We collect, store, and use Your User data on our servers to provide you with
                the website. We may also use data in an aggregated and anonymized form for our own purposes. Our Site
                transfers data to servers that store Your User data in the U.S. We only share User information with
                others under special circumstances as follows:</span></p>
        <p>&nbsp;</p>
        <ul>
            <li><strong><strong>With third parties who work on our behalf to provide you with the
                        Services;</strong></strong></li>
        </ul>
        <ul>
            <li><strong>To comply with laws or to respond to lawful requests and legal process (provided that RentReef
                    will endeavor to notify you if RentReef has received a lawful request for your
                    information);</strong></li>
        </ul>
        <ul>
            <li><strong>To protect the rights and property of RentReef, our agents, customers, and others including to
                    enforce our agreements, policies, and terms of use;</strong></li>
        </ul>
        <ul>
            <li><strong>In an emergency, including to protect the personal safety of any person; and</strong></li>
        </ul>
        <ul>
            <li><strong>For the purposes of a business deal (or negotiation of a business deal) involving sale or
                    transfer of all or a part of our business or assets (business deals may include, for example, any
                    merger, financing, acquisition, divestiture, or bankruptcy transaction or proceeding).</strong></li>
        </ul>
        <p>&nbsp;</p>
        <p><span style="font-weight: 400;">We provide you with access to Your User data and ability to delete any of
                Your User data. We also take commercially reasonable steps to safeguard Your User data.</span></p>
        <p><span style="font-weight: 400;">We may also collect registration and other information about you as our
                customer through our Site. Our collection and use of information collected about you on our website is
                governed by our </span><a href="https://heap.io/privacy"><span style="font-weight: 400;">Privacy
                    Policy</span></a><span style="font-weight: 400;">.</span></p>
        <h4><strong>9. Links and Third-Party Content</strong></h4>
        <p><span style="font-weight: 400;">Our Site may display, or contain links to, third party products, services,
                and websites. Any opinions, advice, statements, services, offers, or other information that constitutes
                part of the content expressed, authored, or made available by other users or other third parties on our
                Site, or which is accessible through or may be located using our Site (collectively, &ldquo;Third Party
                Content&rdquo;) are those of the respective authors or producers and not of us or our shareholders,
                directors, officers, employees, agents, or representatives.</span></p>
        <p><span style="font-weight: 400;">We do not control Third Party Content and do not guarantee the accuracy,
                integrity or quality of such Third Party Content. We are not responsible for the performance of, we do
                not endorse, and we are not responsible or liable for, any Third Party Content or any information or
                materials advertised in any Third Party Content. WE ARE NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR
                INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED TO YOU BY YOUR USE OF OR RELIANCE ON ANY GOODS, WEBSITES, OR
                INFORMATION AVAILABLE ON OR THROUGH ANY THIRD PARTY SERVICE OR THIRD PARTY CONTENT. It is your
                responsibility to evaluate the information, opinion, advice, or other content available on a third-party
                website.</span></p>
        <p><span style="font-weight: 400;">PLEASE DO NOT USE OUR SITE TO: (i) upload, post, email, or otherwise transmit
                any Submission that contains unlawful, harmful, threatening, abusive, harassing, tortious, defamatory,
                vulgar, obscene, libelous, invasive of another&rsquo;s privacy, hateful, or racially, ethnically or
                otherwise objectionable; (ii) harm us or third parties in any way; (iii) impersonate any person or
                entity, or otherwise misrepresent your affiliation with a person or entity; (iv) upload, post, email, or
                otherwise transmit any Submission that you do not have a right to transmit under any law or under
                contractual or fiduciary relationships (such as inside information, proprietary and confidential
                information learned or disclosed as part of employment relationships or under nondisclosure agreements);
                (v) upload, post, email or otherwise transmit any Submission that infringes any patent, trademark, trade
                secret, copyright, or other right of any party; (vi) upload, post, email, or otherwise transmit any
                unsolicited or unauthorized advertising, promotional materials, &ldquo;junk mail,&rdquo;
                &ldquo;spam,&rdquo; &ldquo;chain letters,&rdquo; &ldquo;pyramid schemes,&rdquo; or any other forms of
                solicitation; (vii) upload, post, email, or otherwise transmit any material that contains software
                viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the
                functionality of any computer software or hardware or telecommunications equipment; (viii) interfere
                with or disrupt the Site or servers or networks connected to the Site, or disobey any requirements,
                procedures, policies or regulations of networks connected to the Site; (ix) intentionally or
                unintentionally violate any applicable local, state, national or international law or regulation; (x)
                &ldquo;stalk&rdquo; or otherwise harass another; or (xi) collect or store personal data about other
                users.</span></p>
        <h4><strong>10. Electronic Communications</strong></h4>
        <p><span style="font-weight: 400;">When you visit the Website or send emails to us, you are communicating with
                us electronically. You consent to receive communications from us electronically. We will communicate
                with you by email or by posting notices on this Website. You agree that all agreements, notices,
                disclosures and other communications that we provide to you electronically satisfy any legal requirement
                that such communications be in writing.</span></p>
        <p><span style="font-weight: 400;">If you would like to unsubscribe from receiving promotional emails from us,
                please follow instructions to unsubscribe available at the bottom of the email sent by us. If you have
                any questions, please feel free to email </span><span
                style="font-weight: 400;">legal@rentreef.com</span><span style="font-weight: 400;">.</span></p>
        <h4><strong>11. Trademarks</strong></h4>
        <p><span style="font-weight: 400;">&ldquo;RentReef,&rdquo; the RentReef logo, and any other product or service
                name or slogan displayed on our Site are trademarks of RentReef and its suppliers or licensors, and may
                not be copied, imitated or used, in whole or in part, without the prior written permission of RentReef
                or the applicable third party trademark holder. You may not use any metatags or any other &ldquo;hidden
                text&rdquo; utilizing &ldquo;RentReef&rdquo; or any other name, trademark or product or service name of
                RentReef without our prior written permission. In addition, the look and feel of our Site, including all
                page headers, custom graphics, button icons and scripts, is the service mark, trademark and/or trade
                dress of RentReef and may not be copied, imitated or used, in whole or in part, without our prior
                written permission. All other trademarks, registered trademarks, product names and company names or
                logos mentioned in our Site are the property of their respective owners. Reference to any products,
                services, processes or other information, by trade name, trademark, manufacturer, supplier, or otherwise
                does not constitute or imply endorsement, sponsorship, or recommendation thereof by us.</span></p>
        <h4><strong>12. Copyright Infringement/DMCA Notice</strong></h4>
        <p><span style="font-weight: 400;">If you believe that any content on our Website violates your copyright, and
                you wish to have the allegedly infringing material removed, the following information in the form of a
                written notification (pursuant to 17 U.S.C. &sect; 512(c) (&ldquo;DMCA Takedown Notice&rdquo;) must be
                provided to our Designated Copyright Agent. It is our policy to terminate the accounts of repeat
                infringers.</span></p>
        <p>&nbsp;</p>
        <ul>
            <li><strong><strong>Your physical or electronic signature;</strong></strong></li>
        </ul>
        <ul>
            <li><strong>Identification of the copyrighted work(s) that you claim to have been infringed;</strong></li>
        </ul>
        <ul>
            <li><strong>Identification of the material on our services that you claim is infringing and that you request
                    us to remove;</strong></li>
        </ul>
        <ul>
            <li><strong>Sufficient information to permit us to locate such material;</strong></li>
        </ul>
        <ul>
            <li><strong>Your address, telephone number and e-mail address;</strong></li>
        </ul>
        <ul>
            <li><strong>A statement that you have a good faith belief that use of the objectionable material is not
                    authorized by the copyright owner, its agent, or under the law; and</strong></li>
        </ul>
        <ul>
            <li><strong>A statement that the information in the notification is accurate, and under penalty of perjury,
                    that you are either the owner of the copyright that has allegedly been infringed or that you are
                    authorized to act on behalf of the copyright owner.</strong></li>
        </ul>
        <p>&nbsp;</p>
        <p><span style="font-weight: 400;">The RentReef Copyright Agent to receive the DMCA Takedown Notices is
            </span><span style="font-weight: 400;">legal@rentreef.com</span><span style="font-weight: 400;">. You
                acknowledge that for us to be authorized to take down any content, your DMCA takedown notice must comply
                with all the requirements of this Section 12. Please note that, pursuant to 17 U.S.C. &sect; 512(f), any
                misrepresentation of material fact (falsities) in a written notification automatically subjects the
                complaining party to liability for any damages, costs and attorney&rsquo;s fees incurred by us in
                connection with the written notification and allegation of copyright infringement.</span></p>
        <h4><strong>13. Feedback</strong></h4>
        <p><span style="font-weight: 400;">We may provide you with a mechanism to provide feedback, suggestions, and
                ideas, if you choose, about our Site (&ldquo;Feedback&rdquo;). You agree that we may, in our sole
                discretion, use the Feedback you provide to us in any way, including in future enhancements and
                modifications to our Site. You hereby grant to us and our assigns a perpetual, worldwide, fully
                transferable, sublicensable, irrevocable, royalty free license to use, reproduce, modify, create
                derivative works from, distribute, and display the Feedback in any manner any for any purpose, without
                in any media, software, or technology of any kind now existing or developed in the future, without any
                obligation to provide attribution or compensation to you or any third party.</span></p>
        <h4><strong>14. Disclaimer of Warranties</strong></h4>
        <p><span style="font-weight: 400;">YOUR USE OF THE SITE AND THE SERVICES IS AT YOUR SOLE RISK. THE SITE AND THE
                SERVICES EACH ARE PROVIDED ON AN &ldquo;</span><strong>AS IS</strong><span
                style="font-weight: 400;">&rdquo; AND &ldquo;</span><strong>AS AVAILABLE</strong><span
                style="font-weight: 400;">&rdquo; BASIS. WE AND OUR SUPPLIERS AND LICENSORS EXPRESSLY DISCLAIM ALL
                WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES
                OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. WE DO NOT GUARANTEE
                THE ACCURACY, COMPLETENESS, OR USEFULNESS OF THE SITE OR ANY OF THE SERVICES, AND YOU RELY ON THE SITE
                AND SERVICES AT YOUR OWN RISK. ANY MATERIAL THAT YOU ACCESS OR OBTAIN THROUGH OUR SITE IS DONE AT YOUR
                OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER OR LOSS OF
                DATA THAT RESULTS FROM THE DOWNLOAD OF ANY MATERIAL THROUGH OUR SITE. NO ADVICE OR INFORMATION, WHETHER
                ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH OR FROM OUR SITE WILL CREATE ANY WARRANTY NOT
                EXPRESSLY STATED IN THIS AGREEMENT. SOME STATES MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND YOU MAY HAVE
                OTHER RIGHTS THAT VARY FROM STATE TO STATE.</span></p>
        <h4><strong>15. Limitation of Liability</strong></h4>
        <p><span style="font-weight: 400;">WE AND OUR SUPPLIERS AND LICENSORS WILL NOT BE LIABLE FOR ANY DIRECT,
                INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO,
                DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN
                ADVISED OF THE POSSIBILITY OF THESE DAMAGES), RESULTING FROM YOUR USE OF OUR SITE AND SERVICE CONTENT.
                UNDER NO CIRCUMSTANCES WILL THE TOTAL LIABILITY OF US AND OUR SUPPLIERS AND LICENSORS OF ALL KINDS
                ARISING OUT OF OR RELATED TO YOUR USE OF THE SITE AND SERVICE CONTENT (INCLUDING BUT NOT LIMITED TO
                WARRANTY CLAIMS), REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON
                CONTRACT, TORT, OR OTHERWISE, EXCEED THE AMOUNTS, IF ANY, THAT YOU HAVE PAID TO US FOR YOUR USE OF THE
                SITE AND SERVICE CONTENT.</span></p>
        <h4><strong>16. Indemnity</strong></h4>
        <p><span style="font-weight: 400;">You will indemnify and hold RentReef, our suppliers and licensors, and our
                respective subsidiaries, affiliates, officers, agents, employees, representatives, and assigns
                (&ldquo;RentReef Indemnified Parties&rdquo;) harmless from any and all costs, damages, expenses, and
                liabilities (including but not limited to reasonable attorney fees) resulting from any claim, suit,
                action, demand or proceeding brought by any third party against the RentReef Indemnifies Parties arising
                from any of the following: (i) breach or damage caused by your use of the Site and the Services; (ii)
                your violation of these Website Terms or any other agreements that you may have entered into with
                RentReef; (iii) your violation of any rights of a third party through use of the Site or Services; (iv)
                the negligence, willful misconduct of you, your employees, agents or contractors in the use of our Site
                and Services; and (v) any failure by you or your employees, agents, contractors or invitees to comply
                with applicable laws and regulations.</span></p>
        <h4><strong>17. Publicity</strong></h4>
        <p><span style="font-weight: 400;">You agree that RentReef in its sole discretion, may use your trade names,
                trademarks, service marks, logos, domain names and other distinctive brand features in presentations,
                marketing materials, customer lists, financial reports and Web site listings (including links to your
                website) for the purpose of advertising or publicizing your use of RentReef Sites, Services and products
                (if any).</span></p>
        <h4><strong>18. Governing Law</strong></h4>
        <p><span style="font-weight: 400;">The Website Terms and any disputes thereunder shall be governed by the laws
                of the Republic of Singapore, without respect to its conflict of law principles. Each party to this
                agreement hereby consents to the exclusive jurisdiction and venue of the State Courts of Singapore, for
                any actions not subject to the Dispute Resolutions and Arbitration provision as set forth in Section
                19.</span></p>
        <h4><strong>19. Dispute Resolution and Arbitration</strong></h4>
        <p><span style="font-weight: 400;">In the event of any dispute, claim, question, or disagreement arising from or
                relating to this Agreement or the breach thereof, the parties hereto shall use their best efforts to
                settle the dispute, claim, question, or disagreement (collectively the &ldquo;Dispute&rdquo;). To this
                effect, they shall consult and negotiate with each other in good faith and, recognizing their mutual
                interests, attempt to reach a just and equitable solution satisfactory to both PARTIES including
                escalation to senior management. If they do not reach such solution within a period of 30 days, then,
                upon notice by either party to the other, the Dispute shall be submitted to mediation administered by
                the Singapore International Arbitration Centre. If a dispute arises out of or relates to this Agreement,
                or the breach thereof, and if the dispute cannot be settled through negotiation, the PARTIES agree first
                to try in good faith to settle the dispute by mediation administered by the Singapore International
                Arbitration Centre before resorting to arbitration or some other dispute resolution procedure. If the
                PARTIES are unable to resolve the Dispute through Mediation, the Dispute shall be settled by binding,
                mandatory arbitration administered by the Singapore International Arbitration Centre, and judgment on
                the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. The
                Dispute will be submitted to one Arbitrator, who will be agreed to by both PARTIES. Discovery shall be
                limited to one set of interrogatories, one set of request for admissions, one set of document requests,
                and one deposition of the person identified by the applicable most knowledgeable of the Dispute. The
                decision of the Arbitrator shall be final and binding on the PARTIES and not appealable.</span></p>
        <p><span style="font-weight: 400;">The parties each agree that any dispute resolution proceedings will be
                conducted only on an individual basis and not in a class, consolidated or representative action. IF FOR
                ANY REASON A CLAIM IS INITIATED IN COURT RATHER THAN IN ARBITRATION, WE EACH WAIVE ANY RIGHT TO A JURY
                TRIAL.</span></p>
        <p><span style="font-weight: 400;">Our failure to enforce any right or provision in these Website Terms will not
                constitute a waiver of such right or provision unless acknowledged and agreed to by us in writing. In
                the event that a court of competent jurisdiction finds any provision of these Website Terms to be
                illegal, invalid, or unenforceable, the remaining provisions will remain in full force and
                effect.</span></p>
        <p><span style="font-weight: 400;">THIS AGREEMENT I.E., THE WEBSITE TERMS IS BINDING UPON THE ABOVE DEFINED
                PARTIES I.E., USER/WEBSITE VISITOR. BY SIGNING UP FOR THE SERVICES AND/OR ACCESSING THE SITE, YOU AGREE
                TO BE BOUND BY THESE WEBSITE TERMS AND ANY ADDITIONAL TERMS AS MAY BE ATTACHED HERETO.</span></p>
        <p><span style="font-weight: 400;">The terms and conditions which by their nature are intended to survive
                termination of these Website Terms shall survive, including Restrictions, Disclaimer of Warranties,
                Feedback, Indemnity, and Limitation of Liability.</span></p>
        <h4><strong>20. Miscellaneous</strong></h4>
        <p><span style="font-weight: 400;">If any provision of these Website Terms shall be deemed unlawful, void or for
                any reason unenforceable, then that provision shall be deemed severable from these Website Terms and
                shall not affect the validity and enforceability of any remaining provisions. RentReef may assign any or
                all of its rights hereunder to any party without your consent. You are not permitted to assign any of
                your rights or obligations hereunder without the prior written consent of RentReef and any such
                attempted assignment will be void and unenforceable. These Website Terms constitute the entire agreement
                between you and RentReef regarding your use of the Website, and supersede all prior or contemporaneous
                communications whether electronic, oral or written between you and RentReef regarding your use of the
                Website. The parties confirm that it is their wish that these Website Terms, as well as any other
                documents relating to this Terms, including notices, have been and shall be drawn up in the English
                language only. Except as explicitly stated otherwise, any notices to RentReef shall be given by email to
            </span><span style="font-weight: 400;">legal@RentReef.com</span><span style="font-weight: 400;">. Any
                notices to you shall be provided to you through our Website or given to you via the email address you
                provide to RentReef during the registration process.</span></p>
        <h4><strong>21. Contacting Us</strong></h4>
        <p><span style="font-weight: 400;">If you have any questions or concerns about our Site, Services or these
                Website Terms, you may contact us by email at </span><span
                style="font-weight: 400;">legal@RentReef.com</span><span style="font-weight: 400;">.</span></p>
        <p>&nbsp;</p>
    </div>
</section>