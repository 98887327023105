import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-condition-page',
  templateUrl: './terms-condition-page.component.html',
  styleUrls: ['./terms-condition-page.component.scss']
})
export class TermsConditionPageComponent implements OnInit {
  lastUpdated = new Date('2020-09-06');
  constructor() { }

  ngOnInit() {
  }

}
