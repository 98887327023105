import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {

  @Input()
  show = false;

  // tslint:disable-next-line: variable-name
  _size: number;
  get size(): number {
    return this._size;
  }
  @Input() set size(value: number) {
    this._size = value;
    this.sizeData = [];
    if (value) {
      for (let x = 0; x < value; x++) {
        this.sizeData.push(x);
      }
    }
  }

  sizeData = [];

  constructor() {
    this.size = 3;
  }

  ngOnInit() {
  }

}
