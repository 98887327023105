import { Routes } from '@angular/router';
import { PublicComponent } from './layout/public/public.component';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { EmptyStateComponent } from './infrastructure/shared/components/empty-state/empty-state.component';
import { PrivacyPolicyPageComponent } from './public-pages/privacy-policy-page/privacy-policy-page.component';
import { HowItWorksPageComponent } from './public-pages/how-it-works-page/how-it-works-page.component';
import { AboutUsPageComponent } from './public-pages/about-us-page/about-us-page.component';
import { TermsConditionPageComponent } from './public-pages/terms-condition-page/terms-condition-page.component';
import { VerifyEmailPageComponent } from './public-pages/verify-email-page/verify-email-page.component';
import { BusinessConfigResolver } from './modules/listing/resolvers/business-config.resolver';
export const AppRoutes: Routes = [
    {
        path: '', component: PublicComponent,
        resolve: {
            businessConfigData: BusinessConfigResolver
        },
        children: [
            {
                path: 'profile',
                loadChildren: () => import('./modules/profile/profile.module').then(x => x.ProfileModule),
                canActivate: [
                    AuthGuard
                ],
                data: {
                    preload: true
                }
            },
            {
                path: 'add-listing',
                loadChildren: () => import('./modules/add-listing/add-listing.module').then(x => x.AddListingModule),
                canActivate: [
                    AuthGuard
                ]
            },
            {
                path: 'listing',
                loadChildren: () => import('./modules/listing/listing.module').then(x => x.ListingModule),
                data: {
                    preload: true
                }
            },
            {
                path: 'booking',
                loadChildren: () => import('./modules/booking/booking.module').then(x => x.BookingModule),
                canActivate: [
                    AuthGuard
                ]
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./modules/dashboard/dashboard.module').then(x => x.DashboardModule),
                data: {
                    preload: true
                }
            },
            {
                path: 'messaging',
                loadChildren: () => import('./modules/messaging/messaging.module').then(x => x.MessagingModule),
                canActivate: [
                    AuthGuard
                ]
            },
            {
                path: 'my-listing',
                loadChildren: () => import('./modules/my-listing/my-listing.module').then(x => x.MyListingModule),
                canActivate: [
                    AuthGuard
                ]
            }
        ]
    },
    { path: '404', component: NotFoundComponent },
    {
        path: 'company', component: PublicComponent,
        children: [
            { path: 'about-us', component: AboutUsPageComponent },
            { path: 'how-it-works', component: HowItWorksPageComponent },
        ]
    },
    {
        path: 'legal', component: PublicComponent,
        children: [
            {
                path: 'privacy-policy', component: PrivacyPolicyPageComponent
            },
            {
                path: 'terms', component: TermsConditionPageComponent
            }
        ]
    },
    { path: 'verify-email', component: VerifyEmailPageComponent },
    { path: 'empty-state', component: EmptyStateComponent },
    { path: '**', redirectTo: '/404' },
];
