import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApplicationLoadingService } from '@app/core/services/application-loading.service';
import { ListingService } from '@app/core/services/listing.service';
import { ReviewService } from '@app/core/services/review.service';
import { GET_REVIEW_CATEGORY_TYPE_FORMGROUP_SETTINGS, GET_REVIEW_CATEGORY_TYPE_STARS } from '@app/helpers/review-category-type.enum';
import { Listing } from '@app/models/listing.model';
import { RentDetail } from '@app/models/rent-detail.model';
import { Review } from '@app/models/review.model';
import { User } from '@app/models/user.model';
import { ImageUtil } from '@app/shared/utils/image.util.service';
import { NotifierService } from 'angular-notifier';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-review-item',
  templateUrl: './review-item.component.html',
  styleUrls: ['./review-item.component.scss']
})
export class ReviewItemComponent implements OnInit, OnDestroy, OnChanges {

  unsubscribe$ = new Subject();

  @Input() user: User;
  @Input() listingId: string;
  @Input() rentDetail: RentDetail;
  @Input() totalLength: number = 0;
  @Input() index: number = 0;
  @Output() clickAction: EventEmitter<any> = new EventEmitter();

  loading = true;

  starRatingConfig = {
    initialRating: 3,
    starCount: 5
  };

  reviewForm = this.formBuilder.group({
    ...GET_REVIEW_CATEGORY_TYPE_FORMGROUP_SETTINGS
  });
  reviewFormStars = GET_REVIEW_CATEGORY_TYPE_STARS;

  listing$: Observable<Listing>;

  constructor(
    private listingService: ListingService,
    private reviewService: ReviewService,
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private imageUtil: ImageUtil,
    private applicationLoadingService: ApplicationLoadingService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.listing$ = this.listingService.getListDetails(this.listingId);
  }

  ngOnInit() {
    this.listing$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.loading = false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onRatingChanged(rating: number, key: string) {
    const value = {};
    value[key] = rating;
    this.reviewForm.patchValue({
      ...value
    });
  }

  sendReview() {
    let review = {
      rentDetailId: this.rentDetail.id,
      userId: this.user.uid,
      listingId: this.listingId
    } as Review;

    if (this.reviewForm.valid) {
      this.applicationLoadingService.toggleLoading(true);
      review = {
        ...review,
        ...(this.reviewForm.value)
      };
      this.reviewService.addReview(review)
        .pipe(take(1))
        .subscribe(() => {
          this.notifierService.notify('success', 'Review sent!');
          this.clickAction.emit('review');
          this.reviewForm.reset();
          this.applicationLoadingService.toggleLoading(false);
        });
    } else {
      this.notifierService.notify('error', 'Invalid review details');
    }
  }

  clickSkip() {
    this.clickAction.emit('skip');
  }

  getBackgroundImageStyle(url: string) {
    return this.imageUtil.getBackgroundImageStyle(url);
  }

}
