import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mat-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class StarRatingComponent implements OnInit {

  @Input('rating') public rating: number;
  @Input('starCount') private starCount: number;
  @Input('id') id: string;
  @Output() private ratingUpdated = new EventEmitter();

  public ratingArr = [];

  constructor() {
  }


  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }

  onClick(rating: number) {
    this.rating = rating;
    this.ratingUpdated.emit(rating);
    // return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'selected';
    } else {
      return 'unselected';
    }
  }
}

