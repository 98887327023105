import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { from } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { categories } from 'src/app/helpers/categories';
import { AlgoliaService } from '@app/core/services/algolia.service';

@Component({
  selector: 'app-custom-typehead',
  templateUrl: './custom-typehead.component.html',
  styleUrls: ['./custom-typehead.component.css'],
  animations: [
    trigger('toggle', [
      state('in', style({ opacity: 1 })),
      transition('void => *', [style({ opacity: 0.25 }), animate(300)]),
      transition('* => void', [animate(300, style({ opacity: 0.25 }))])
    ])
  ]
})
export class CustomTypeheadComponent implements OnInit, OnChanges {
  @ViewChild('selectionWrap', { static: true }) selectionWrap: ElementRef;
  @Output() triggerFalse: EventEmitter<boolean> = new EventEmitter();
  @Output() checkSearch: EventEmitter<boolean> = new EventEmitter();
  @Output() categorySelected: EventEmitter<any> = new EventEmitter();
  @Input() trigger;
  @Input() categorySelectedInput: any;
  @Input() inputSearched: any;
  @Input() isSearching: any;
  categoriesGroups = categories;
  lists: [];
  footer = false;

  constructor(
    private renderer: Renderer2,
    private algoliaService: AlgoliaService
  ) { }

  ngOnInit() { }

  onOptionSelect(type, value) {
    this.categorySelected.emit({ type, value });
  }

  ngOnChanges() {
    this.trigger ? this.showOptions() : this.closeOptions();
    if (this.inputSearched && !this.isSearching && this.inputSearched.length > 2) {
      this.checkSearch.emit(true);
      this.footer = true;
      this.isSearching = true;
      this.lists = [];

      from(this.algoliaService.search(this.inputSearched))
        .pipe(
          debounceTime(500),
          take(1)
        )
        .subscribe(data => {
          this.lists = data.hits;
          this.footer = !this.lists.length;
          this.isSearching = false;
          this.checkSearch.emit(false);
        });
    }
  }

  isCategorySelected(category) {
    return this.categorySelectedInput.includes(category);
  }

  private showOptions() {
    this.renderer.addClass(this.selectionWrap.nativeElement, 'open');
  }

  private closeOptions() {
    this.renderer.removeClass(this.selectionWrap.nativeElement, 'open');
  }

  getFirstImageUrl(images: any) {
    return images ? images[0] : '';
  }
}
