import { CloudImagePipe } from './cloud-image.pipe';
import { environment } from '@app/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { CLOUD_IMAGE_URL, DEFAULT_LISTING_IMAGE } from '@app/helpers/images';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

@Pipe({
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {
  defaultImage: string = DEFAULT_LISTING_IMAGE;

  constructor(
    private http: HttpClient,
    private cloudImagePipe: CloudImagePipe
  ) { }

  transform(url: string, ...args: any[]): Observable<string> {
    let finalUrl = this.cloudImagePipe.transform(url);
    return this.http
      .get(finalUrl, { responseType: 'blob' })
      .pipe(
        startWith(this.defaultImage),
        map(() => finalUrl || this.defaultImage),
        catchError((err) => {
          return of(this.defaultImage);
        })
      );
  }

}
