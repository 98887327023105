import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotifierModule } from 'angular-notifier';
import { CustomSearchComponent } from './components/custom-search/custom-search.component';
import { CustomTypeheadComponent } from './components/custom-typehead/custom-typehead.component';
import { DetailsComponent } from './components/details/details.component';
import { ItemsComponent } from './components/items/items.component';
import { LandingComponent } from './components/landing/landing.component';
import { ListingLocationMapComponent } from './components/listing-location-map/listing-location-map.component';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { LoginComponent } from './components/login/login.component';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { MessageComponent } from './components/message/message.component';
import { NewListingInitComponent } from './components/new-listing-init/new-listing-init.component';
import { PaymentOptionListItemComponent } from './components/payment-option-list-item/payment-option-list-item.component';
import { RegisterComponent } from './components/register/register.component';
import { RiCalendarComponent } from './components/ri-calendar/ri-calendar.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { DropzoneDirective } from './directives/dropzone.directive';
import { FilterCategoriesPipe } from './pipes/filter-categories.pipe';
import { FilterCategoryGroupPipe } from './pipes/filter-category-group.pipe';
import { ImageUrlPipe } from './pipes/image-url.pipe';
import { RentItTag } from './pipes/rent-it-tag.pipe';
import { DateUtil } from './utils/date-util.service';
import { ImageUtil } from './utils/image.util.service';
import { MessageUtil } from './utils/message-util.service';
import { LazyLoadImgDirective } from './directives/lazy-load-img.directive';
import { ListingSummaryPaneComponent } from './components/listing-summary-pane/listing-summary-pane.component';
import { UploaderV2Component } from './components/uploader-v2/uploader-v2.component';
import { ReviewsComponent } from '../../modules/listing/containers/reviews/reviews.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { ReviewModalComponent } from './components/review-modal/review-modal.component';
import { ReviewItemComponent } from './components/review-modal/review-item/review-item.component';
import { OrderPipe } from './pipes/order.pipe';
import { RatingStarsLabelComponent } from './components/rating-stars-label/rating-stars-label.component';
import { SwiperModule } from 'swiper/angular';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { PriceMinMaxFormComponent } from './components/price-min-max-form/price-min-max-form.component';
import { NotificationListItemComponent } from './components/notification-list-item/notification-list-item.component';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { ShortenPipe } from './pipes/shorten.pipe';
import { NgImageSliderModule } from 'ng-image-slider';
import { McpPipe } from './pipes/mcp.pipe';
import { CloudImagePipe } from './pipes/cloud-image.pipe';
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

@NgModule({
  declarations: [
    LandingComponent,
    LoginComponent,
    MainNavComponent,
    MainFooterComponent,
    RegisterComponent,
    UploadTaskComponent,
    UploaderComponent,
    ItemsComponent,
    MessageComponent,
    RiCalendarComponent,
    DetailsComponent,
    CustomSearchComponent,
    NewListingInitComponent,
    SpinnerComponent,
    PaymentOptionListItemComponent,
    CustomTypeheadComponent,
    LoadingDialogComponent,
    ReviewsComponent,
    StarRatingComponent,
    ConfirmationModalComponent,

    DropzoneDirective,

    FilterCategoriesPipe,
    FilterCategoryGroupPipe,
    ImageUrlPipe,
    TimeAgoPipe,
    OrderPipe,
    ShortenPipe,

    RentItTag,
    SkeletonComponent,
    ListingLocationMapComponent,
    LazyLoadImgDirective,
    UploaderV2Component,
    ListingSummaryPaneComponent,
    EmptyStateComponent,
    ReviewModalComponent,
    ReviewItemComponent,
    RatingStarsLabelComponent,
    PriceMinMaxFormComponent,
    NotificationListItemComponent,
    UnderConstructionComponent,
    McpPipe,
    CloudImagePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    FormsModule,
    NotifierModule,
    NgbModule,
    NgbCarouselModule,
    DragDropModule,

    MatBadgeModule,
    MatMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatNativeDateModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCardModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatListModule,
    MatIconModule,

    AgmCoreModule,
    SwiperModule,
    NgImageSliderModule,
    FlexLayoutModule
  ],
  exports: [
    LandingComponent,
    LoginComponent,
    MainNavComponent,
    MainFooterComponent,
    RegisterComponent,
    UploadTaskComponent,
    UploaderComponent,
    ItemsComponent,
    MessageComponent,
    RiCalendarComponent,
    DetailsComponent,
    CustomSearchComponent,
    NewListingInitComponent,
    SpinnerComponent,
    PaymentOptionListItemComponent,
    CustomTypeheadComponent,
    LoadingDialogComponent,
    ListingLocationMapComponent,
    SkeletonComponent,
    UploaderV2Component,
    ListingSummaryPaneComponent,
    StarRatingComponent,
    RatingStarsLabelComponent,
    PriceMinMaxFormComponent,
    UnderConstructionComponent,
    ConfirmationModalComponent,

    DropzoneDirective,
    LazyLoadImgDirective,

    FilterCategoriesPipe,
    FilterCategoryGroupPipe,
    ImageUrlPipe,
    RentItTag,
    TimeAgoPipe,
    OrderPipe,
    ShortenPipe,
    McpPipe,

    AgmCoreModule,
    MatGoogleMapsAutocompleteModule,
    NgImageSliderModule,
    FlexLayoutModule
  ],
  entryComponents: [
    LoginComponent,
    MainNavComponent,
    MainFooterComponent,
    RegisterComponent,
    UploadTaskComponent,
    UploaderComponent,
    ItemsComponent,
    MessageComponent,
    RiCalendarComponent,
    DetailsComponent,
    CustomSearchComponent,
    NewListingInitComponent,
    SpinnerComponent,
    PaymentOptionListItemComponent,
    SkeletonComponent,
    SpinnerComponent,
    StarRatingComponent,
    ReviewModalComponent
  ],
  providers: [
    ImageUtil,
    DateUtil,
    MessageUtil,
    CurrencyPipe,
    ImageUrlPipe,
    CloudImagePipe,
    { provide: CDK_DRAG_CONFIG, useValue: DragConfig }
  ]
})
export class SharedModule { }
