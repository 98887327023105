import { Injectable } from '@angular/core';
import { IdentityDetails } from '@app/models/hubspot-events';

@Injectable({
  providedIn: 'root'
})
export class HubspotService {

  private _hsq: any[] = [];

  private HSQ_KEY = '_hsq';

  constructor() {
    this._hsq = window[this.HSQ_KEY] || [];
  }

  pushEvent(type: string, data?: any | IdentityDetails) {
    this._hsq.push([type, data]);
  }

}
