import { NgModule, Optional, SkipSelf, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormCreationGuard } from './guards/form-creation.guard';
import { StripeAccountGuard } from './guards/stripe-account.guard';
import { AuthGuard } from './guards/auth.guard';
import { HomeGuard } from './guards/home.guard';
import { ListingIdValidGuard } from './guards/listing-id-valid.guard';
import { RouterModule } from '@angular/router';
import { AddListingService } from './services/add-listing.service';
import { AlgoliaService } from './services/algolia.service';
import { DeleteListingService } from './services/delete-listing.service';
import { ListingService } from './services/listing.service';
import { LoadFormService } from './services/load-form.service';
import { MapsService } from './services/maps.service';
import { MessageService } from './services/message.service';
import { PaymentSourceService } from './services/payment-source.service';
import { RouterInterceptorService } from './services/router-interceptor.service';
import { SaveFormService } from './services/save-form.service';
import { SearchService } from './services/search.service';
import { UploadService } from './services/upload.service';
import { AuthService } from './auth/auth.service';
import { ProfileService } from './auth/profile.service';
import { UserService } from './auth/user.service';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { RestInterceptor } from './interceptor/rest.interceptor';
import { GlobalErrorHandler } from './handlers/global-error-handler';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    AngularFireStorageModule,
    HttpClientJsonpModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RestInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },

    FormCreationGuard,
    StripeAccountGuard,
    AuthGuard,
    HomeGuard,
    ListingIdValidGuard
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        FormCreationGuard,
        StripeAccountGuard,
        AuthGuard,
        HomeGuard,
        ListingIdValidGuard
      ]
    };
  }
}
