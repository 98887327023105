import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { ProfileService } from '@app/core/auth/profile.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-verify-email-page',
  templateUrl: './verify-email-page.component.html',
  styleUrls: ['./verify-email-page.component.scss']
})
export class VerifyEmailPageComponent implements OnInit, OnDestroy {

  profileListener: Subscription;
  hasError = false;

  constructor(
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private profileService: ProfileService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.profileListener = this.authService.user.subscribe(user => {
      if (user) {
        this.afAuth.currentUser.then((res) => {
          console.log(res);
          user.emailVerified = res.emailVerified;
          this.profileService.updatedProfile(user, res.uid)
            .then((res2) => {
              this.router.navigate(['/profile']);
            }).catch(err => {
              this.hasError = true;
            });
        });
      } else {
        this.router.navigate(['/notfound'])
      }
    }, (e) => {
      this.hasError = false;
    });
  }

  ngOnDestroy(): void {
    if (this.profileListener) {
      this.profileListener.unsubscribe();
    }
  }
}
