import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AuthService } from '@app/core/auth/auth.service';
import { DOCUMENT_KEY_RENTS, DOCUMENT_KEY_RENT_DETAILS } from '@app/core/services/listing.service';
import { RENTS, RENT_DETAILS } from '@app/helpers/firestore.keys';
import {
  ReservationStatusTypes
} from '@app/helpers/reservation-status-types.enum';
import { UserReviewStatus } from '@app/helpers/user-review-status.enum';
import * as firebase from 'firebase';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BusinessConfigModel } from '../../../models/business-config.model';

@Injectable({
  providedIn: 'root'
})
export class RentsService {

  businessConfig: BusinessConfigModel;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private fun: AngularFireFunctions
  ) { }

  getRentsToBeReviewed() {
    return this.authService.user
      .pipe(
        switchMap(user => {
          const renterKey = 'renter';
          const endDateKey = 'endDate';
          const userReviewStatusKey = 'userReviewStatus';
          return this.afs.collection(RENTS)
            .get()
            .pipe(
              map(docs => {
                const listings: string[] = [];
                docs.forEach(doc => listings.push(doc.id));
                return listings;
              }),
              map(async listings => {
                const rentDetailsToReview = listings.map(listing =>
                  this.afs.collection(RENTS)
                    .doc(listing)
                    .collection(RENT_DETAILS)
                    .ref
                    .where(renterKey, '==', user ? user.uid : null)
                    .where(userReviewStatusKey, '==', UserReviewStatus.TO_REVIEW)
                    .orderBy(endDateKey, 'desc')
                    .get()
                    .then(docs => {
                      const result = [];
                      docs.forEach(doc =>
                        result.push({
                          listingId: listing,
                          rentDetail: {
                            id: doc.id,
                            ...doc.data()
                          }
                        })
                      );
                      return result;
                    })
                );
                return await Promise.all([...rentDetailsToReview]);
              }),
              switchMap(promises => {
                return from(promises);
              }),
              map(data => [].concat(...data))
            );
        })
      );
  }

  updateReservationStatus(
    listingId: string,
    rentDetailId: string,
    reservationStatusType: ReservationStatusTypes
  ) {
    return this.afs.collection(DOCUMENT_KEY_RENTS)
      .doc(listingId)
      .collection(DOCUMENT_KEY_RENT_DETAILS)
      .doc(rentDetailId)
      .update({
        reservationStatus: reservationStatusType,
        updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
      });
  }

  getBusinessConfig(): Observable<BusinessConfigModel> {
    return this.fun.httpsCallable('businessConfig')({})
      .pipe(
        map(d => {
          this.businessConfig = d;

          return d;
        })
      );
  }

}
