import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BusinessConfig } from '../../../common/business-config';

@Injectable({
  providedIn: 'root'
})
export class LoadFormService {
  constructor(
    private fb: FormBuilder,
    private http: HttpClient
  ) { }

  createNewListingFormObject(formKey: string): FormGroup {
    const dateTomorrow = new Date();
    dateTomorrow.setDate(dateTomorrow.getDate() + 1);

    const basicListingValidators = {
      name: ['', [Validators.required, Validators.maxLength(BusinessConfig.Listing.MaxNameSize)]],
      description: ['', Validators.compose([Validators.required])],
      category: [null, [Validators.compose([Validators.required])]],
      rentalPrice: [0, Validators.compose([Validators.required, Validators.min(0.5)])],
      weeklyDiscount: [0],
      monthlyDiscount: [0],
      address: ['', Validators.compose([Validators.required])],
      location: ['', Validators.compose([Validators.required])],
      currency: [BusinessConfig.Listing.Currency.Default],
      images: [null],
      startDate: [dateTomorrow, Validators.compose([Validators.required])],
      endDate: [''],
      noUntilDate: [true],
      status: [true]
    };

    switch (formKey) {
      case 'basic-info':
        return this.fb.group({
          ...basicListingValidators
        });
      case 'edit-listing':
        return this.fb.group({
          ...basicListingValidators,
          user: [''],
          stripe_account_ID: [''],
        });
      default:
        break;
    }
  }

  getCountries() {
    return this.http.get('https://restcountries.eu/rest/v2/all');
  }
}
