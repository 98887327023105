import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PUBLIC_LINKS } from 'src/app/common/public-page-links';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {
  publicLinks = PUBLIC_LINKS;
  constructor(private router: Router) { }

  ngOnInit() { }
}
