import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UserService } from '@app/core/auth/user.service';
import { ApplicationLoadingService } from '@app/core/services/application-loading.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { asyncScheduler, of, Subject } from 'rxjs';
import { switchMap, takeUntil, throttleTime } from 'rxjs/operators';
import { BusinessConfig } from './common/business-config';
import { EventTypes } from './helpers/hubspot';
import { HubspotService } from './infrastructure/core/services/hubspot.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = BusinessConfig.COMPANY_NAME;

  unsubscribe$ = new Subject();

  constructor(
    private router: Router,
    private userService: UserService,
    private googleTagManager: GoogleTagManagerService,
    private hubspotService: HubspotService,
    public loadingService: ApplicationLoadingService
  ) {
    const bodyElem = document.getElementById('rentReff');
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        bodyElem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    });
  }

  ngOnInit(): void {
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loadingService.toggleLoading(true);
        }
        if (event instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: event.url
          };
          this.googleTagManager.pushTag(gtmTag);
          this.hubspotService.pushEvent(EventTypes.SET_PATH, event.url);
          this.loadingService.toggleLoading(false);
        }
      });

    const thrtottleTime = 5000;
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        throttleTime(thrtottleTime, asyncScheduler, { trailing: true }),
        switchMap((event) => {
          if (event instanceof NavigationStart) {
            return this.userService.updateUserActivity(thrtottleTime);
          }
          return of(null);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
