import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationLoadingService {
  // tslint:disable-next-line: variable-name
  private _isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this._isLoading.asObservable();
  constructor() { }

  toggleLoading(state?: boolean) {
    if (!state) {
      const currentVal = this._isLoading.value;
      this._isLoading.next(!currentVal);
    } else {
      this._isLoading.next(state);
    }
  }
}
