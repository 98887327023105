import { Pipe, PipeTransform } from '@angular/core';
import { RentsService } from '../../core/services/rents.service';

/// Multi Currency Pipe
@Pipe({
  name: 'mcp'
})
export class McpPipe implements PipeTransform {

  /**
   *
   */
  constructor(private rentService: RentsService) {
  }

  transform(value: number, includeServiceFee = true): unknown {
    let amount = +value;

    if (includeServiceFee) {
      amount =  amount * (this.rentService.businessConfig.serviceFee + 1);
    }

    return `${this.rentService.businessConfig.currencySupported.symbol} ${Number(amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
  }

}
