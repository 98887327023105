import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-how-it-works-page',
  templateUrl: './how-it-works-page.component.html',
  styleUrls: ['./how-it-works-page.component.scss']
})
export class HowItWorksPageComponent implements OnInit {

  form: FormGroup;
  categories$ = new Observable<any[]>();
  childCategories$;
  constructor(
    private afs: AngularFirestore,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      name: new FormControl('', []),
      parentId: new FormControl(null, []),
      childId: new FormControl(null, [])
    });
  }

  async ngOnInit() {
    this.categories$ = this.afs.collection('categories', ref => ref.where('parentId', '==', null)).snapshotChanges()
      .pipe(
        map(x => {
          const item = [];
          for (const i of x) {
            const data: any = i.payload.doc.data();
            item.push({
              id: (i.payload.doc as any).id,
              ...data
            });
          }
          return item;
        })
      );
    this.categories$.subscribe((res) => { console.log(res); });
    this.form.get('parentId').valueChanges
      .subscribe((res) => {
        this.childCategories$ = this.afs.collection('categories', ref => ref.where('parentId', '==', res)).snapshotChanges()
          .pipe(
            map(x => {
              const item = [];
              for (const i of x) {
                const data: any = i.payload.doc.data();
                item.push({
                  id: (i.payload.doc as any).id,
                  ...data
                });
              }
              return item;
            })
          );
      });
  }

  async submit() {
    const payload = this.form.value;
    await this.afs.collection('categories')
      .add(payload);
    this.form.get('name').reset();
  }

}
