import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { NEW_LISTING } from './../../../helpers/constants';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AddListingService {
  forms: any = {};
  forms$ = new BehaviorSubject<any>({});
  listingID: string;
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore) { }

  createNewListing(userID, stripeAccnt, formValues?) {
    let listing = { ...NEW_LISTING, ...formValues };
    listing.user = userID;
    listing.stripe_account_ID = stripeAccnt;
    listing.name = formValues.name;
    listing.images = formValues.images;
    listing.description = formValues.description;
    listing.rentalPrice = parseInt(formValues.rentalPrice, 10);
    listing.status = true;
    return this.afs.collection('listings').add(listing);
  }

  createUserListingRelationship(listingID, userID) {
    const data = {};
    data[listingID] = true;
    return this.afs
      .collection(`user-has-listings`)
      .doc(`${userID}`)
      .set({ listings: firebase.firestore.FieldValue.arrayUnion(listingID) }, { merge: true });
  }

  createListingUserRelationship(listingID, userID) {
    const data = {};
    data[userID] = true;
    return this.afs
      .collection(`listing-has-user`)
      .doc(`${listingID}`)
      .set(data, { merge: true });
  }

  saveListing(data, listingID) {
    data.status = true;
    return this.afs
      .collection(`listings`)
      .doc(`${listingID}`)
      .update(data);
  }

  getListingData(listingID) {
    return this.afs.doc(`listings/${listingID}`).valueChanges();
  }

  setListingID(lisitngID) {
    this.listingID = lisitngID;
  }

  saveForm(formName: string, formData: any) {
    this.forms[formName] = formData;
    this.forms$.next(this.forms);
  }

  getForm(formName: string) {
    return this.forms[formName];
  }

  getListingID() {
    return this.listingID;
  }
}
