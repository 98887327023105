import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { ListingService } from '../services/listing.service';

@Injectable({
  providedIn: 'root'
})
export class ListingIdValidGuard implements CanActivate {
  private readonly notifier: NotifierService;
  constructor(
    private router: Router,
    private listingService: ListingService,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const listingId = route.params.listingId;
    return this.listingService.getListDetails(listingId).pipe(
      map(data => {
        if (data === undefined) {
          this.notifier.notify('error', 'There is no item with that ID!');
          this.router.navigate(['404']);
        } else {
          return true;
        }
      })
    );
  }
}
