export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCCd_jYVhoKARcykbM1vvL87rcVGBAQdd8",
    authDomain: "rentreef.com",
    projectId: "rentreef-dd2e1",
    storageBucket: "rentreef-dd2e1.appspot.com",
    messagingSenderId: "485567074046",
    appId: "1:485567074046:web:e4db0ca777bec681a11faf",
    measurementId: "G-GLECWZL00L"
  },
  supplier_url: 'http://localhost:4300/',
  algolia: {
    appId: 'MNJTF8AKNT',
    apiKey: '717c357b02035d1750442677695f88b6',
    indexKey: 'prod_listing',
    indexRentalPriceKey: 'prod_listing_rental_price_{sort}'
  },
  stripe: {
    key: 'pk_live_w58dI3eKYY6xiCmoq08dkZ42'
  },
  cloudImage: {
    url: 'cloudimg.io/v7',
    key: 'ayitocjztq'
  },
  firebase_new_config: {
    bucket: 'images.rentreef.com'
  }
};

export const serviceFee = 0.15;
export const ownerShare = 0.85;
