import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { ChatListingItem } from '@app/models/chat-listing-item.model';
import { ChatMessage } from '@app/models/chat-message';
import { ChatRoom } from '@app/models/chat-room.model';
import { User } from '@app/models/user.model';
import { MessageUtil } from '@app/shared/utils/message-util.service';
import * as firebase from 'firebase';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, take, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CHAT_ROOMS } from '../../../helpers/firestore.keys';
import { AuthService } from './../auth/auth.service';
import { SaveFormService } from './save-form.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private afs: AngularFirestore,
    private saveFormService: SaveFormService,
    private messageUtil: MessageUtil,
    private authService: AuthService
  ) { }

  checkIfChatsExists(roomId) {
    return this.afs.doc(`chats/${roomId}`).get();
  }

  initializeChatRoom(
    initiatorUser: User,
    receiverUser: User,
    chatListingItem: ChatListingItem,
    message?: string
  ): Observable<ChatRoom | DocumentReference> {
    return from(new Promise<ChatRoom | DocumentReference>(resolve => {
      this.getChatRoomWithInitiatorReceiver(initiatorUser.uid, receiverUser.uid)
        .pipe(take(1))
        .subscribe(chatRooms => {
          if (chatRooms.length && chatRooms[0] && chatRooms[0].id) {
            this.setChatRoomListing(chatRooms[0].id, chatListingItem, initiatorUser)
              .then(() => {
                this.sendChatMessageOnInit(chatRooms[0].id, initiatorUser, message);
                resolve(chatRooms[0]);
              });
          } else {
            const roomData = this.saveFormService
              .curateMessageRoomData(receiverUser, initiatorUser);
            this.initiateChatRoom(roomData).then(chatRoom => {
              this.setChatRoomListing(chatRoom.id, chatListingItem, initiatorUser)
                .then(() => {
                  this.sendChatMessageOnInit(chatRoom.id, initiatorUser, message);
                  resolve(chatRoom);
                });
            });
          }
        });
    }));
  }

  private sendChatMessageOnInit(chatRoomId: string, user: User, message?: string) {
    if (message) {
      const chatMessage = this.messageUtil
        .createNewMessage(message, user);
      this.sendMessage(chatRoomId, chatMessage);
    }
  }

  getUserChatRooms(userId, checkExist?) {
    const col = this.afs
      .collection<ChatRoom>(
        'chat-rooms',
        ref => ref.where('participants', 'array-contains', userId)
          .orderBy('lastUpdatedAt')
      )
      .snapshotChanges();
    if (checkExist) {
      return col
        .pipe(
          tap(list => {
            return list;
          }),
          take(1)
        );
    } else {
      return col;
    }
  }

  getUserChatRoomsMapped(userId, checkExist?): Observable<ChatRoom[]> {
    return this.afs
      .collection<ChatRoom>(
        'chat-rooms',
        ref => ref.where('participants', 'array-contains', userId)
          .orderBy('lastUpdatedAt', 'desc')
      )
      .snapshotChanges()
      .pipe(map(
        snap => snap.map(
          data => ({
            id: (data.payload.doc as any).id,
            ...data.payload.doc.data()
          })
        )
      ));
  }

  getChatRoomWithInitiatorReceiver(
    initiatorId: string,
    receiverId: string
  ): Observable<ChatRoom[]> {
    const initiatorAndReceiver = [initiatorId, receiverId];
    return this.afs
      .collection<ChatRoom>('chat-rooms',
        ref => ref.where('initiatorUserId', 'in', initiatorAndReceiver)
      )
      .snapshotChanges()
      .pipe(map(
        data => data
          .filter(snap => snap.payload.doc.data().receiverUserId === initiatorId
            || snap.payload.doc.data().receiverUserId === receiverId)
          .map(
            snap => ({
              id: (snap.payload.doc as any).id,
              ...snap.payload.doc.data()
            })
          )
      ));
  }

  sendMessageV2(
    user: User,
    isInitiator: boolean,
    chatRoom: ChatRoom,
    messageData: ChatMessage
  ): Promise<void> {
    const time = firebase.firestore.Timestamp.fromDate(new Date());

    const updatedChatRoom: any = {
      lastUpdatedAt: time,
      lastMessage: messageData.message,
      lastMessageUserId: user.uid,
    };

    if (!chatRoom.chats) {
      updatedChatRoom.chats = true;
    }

    if (isInitiator) {
      updatedChatRoom.receiverUserUnreads = ++chatRoom.receiverUserUnreads;
    } else {
      updatedChatRoom.initiatorUserUnreads = ++chatRoom.initiatorUserUnreads;
    }

    return this.sendMessage(chatRoom.id, messageData, chatRoom.chats)
      .then(() => this.updateChatRoom(chatRoom.id, updatedChatRoom));
  }

  sendMessage(roomID: string, messageData: ChatMessage, chats?) {
    return this.updateChatRoom(roomID, {
      lastMessageUserId: messageData.userId,
      lastUpdatedAt: new Date()
    })
      .then(() => this.afs
        .collection('chats')
        .doc(roomID)
        .collection(`messages`)
        .add(messageData)
      );
  }

  updateChatRoom(roomID, updatedData) {
    return this.afs
      .collection('chat-rooms')
      .doc(roomID)
      .update(updatedData);
  }

  checkIfChatRoomExist(roomID) {
    return this.afs
      .collection('chat-rooms')
      .doc(`${roomID}`)
      .valueChanges();
  }

  getRoomMessages(roomID) {
    return this.afs
      .collection(`chats`)
      .doc(`${roomID}`)
      .collection(`messages`, ref => ref.orderBy('time', 'asc'))
      .valueChanges();
  }

  getLastRoomMessage(roomID: string): Observable<ChatMessage> {
    return this.afs
      .collection(`chats`)
      .doc(`${roomID}`)
      .collection<ChatMessage>(`messages`, ref => ref.orderBy('time', 'desc').limit(1))
      .valueChanges()
      .pipe(map(collection => collection[0]));
  }

  initiateChatRoom(roomData) {
    return this.afs.collection<ChatRoom>('chat-rooms').add(roomData);
  }

  setChatRoomListing(
    roomId: string,
    chatListingItem: ChatListingItem,
    user: User
  ): Promise<void> {
    return this.updateChatRoom(roomId, {
      lastMessageUserId: user.uid,
      lastUpdatedAt: new Date()
    })
      .then(() => this.afs.collection<ChatRoom>('chat-rooms')
        .doc(roomId)
        .update({ chatListingItem }));
  }

  getUserUnreadMessagesCount(): Observable<number> {
    return this.authService.user
      .pipe(
        switchMap(user => {
          return !!user ? this.afs.collection<ChatRoom>(
            CHAT_ROOMS,
            ref => ref.where('participants', 'array-contains', user.uid)
          )
            .valueChanges()
            .pipe(
              map(chatRooms =>
                (chatRooms || [])
                  .filter(chatRoom => chatRoom.initiatorUserId === user.uid
                    ? !!chatRoom.initiatorUserUnreads
                    : !!chatRoom.receiverUserUnreads
                  )
                  .length
              ),
              distinctUntilChanged((a, b) => a === b)
            )
            : of(0)
        }),
        debounceTime(1500)
      );
  }

}
