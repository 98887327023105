import { Listing } from 'src/app/models/listing.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchService } from '@app/core/services/search.service';
import { categories } from '@app/helpers/categories';
import { PUBLIC_LINKS } from 'src/app/common/public-page-links';
import { ListingService } from 'src/app/infrastructure/core/services/listing.service';
import { Observable } from 'rxjs';
import { ImageUtil } from '@app/shared/utils/image.util.service';
import { timingSafeEqual } from 'crypto';
import { AuthService } from '@app/core/auth/auth.service';
import { interval, Subscription } from 'rxjs';
import { BusinessConfig } from 'src/app/common/business-config';
import { RentsService } from '../../../core/services/rents.service';
import { BusinessConfigModel } from '../../../../models/business-config.model';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  categoriesGroups = categories;
  searchForm: FormGroup;
  fromMinDate = new Date();
  toMinDate: any;
  list: any;
  client: any;
  index: any;
  public inputSelected: any = null;
  publicLinks = PUBLIC_LINKS;

  loading = false;

  searchConfig = {
    appId: 'IQGOZKF8LM',
    apiKey: 'b7dd5e61306273891d1f1f90e0771230',
    indexName: 'dev_listing'
  };
  showResults = false;

  exploredData: Observable<Listing[]>;
  exploredList: Listing[];

  topRatedData: Observable<Listing[]>;
  topRatedList: Listing[];

  listingItem = "tech stuff";
  mySubscription: Subscription;

  companyName = BusinessConfig.COMPANY_NAME;

  businessConfig: BusinessConfigModel;

  constructor(
    private fb: FormBuilder,
    private searchService: SearchService,
    private router: Router,
    private listingService: ListingService,
    private imageUtil: ImageUtil,
    public authService: AuthService,
    private rentService: RentsService,
  ) {
    this.businessConfig = this.rentService.businessConfig;
  }

  ngOnInit() {
    this.initForm();
    this.loading = false;

    this.exploredData = this.listingService.getExploredList();

    const exploredListener = this.exploredData.subscribe(lists => {
      this.exploredList = lists;
      exploredListener.unsubscribe();
    })

    this.topRatedData = this.listingService.getTopRatedList();

    const ratedListener = this.topRatedData.subscribe(lists => {
      this.topRatedList = lists;
      ratedListener.unsubscribe();
    })

    this.mySubscription = interval(400).subscribe((x => {
      this.changeListingText(Math.floor(Math.random() * 9));
    }));
  }

  get ifLogged(): boolean {
    return localStorage.getItem('user') ? true : false;
  }

  searchChanged(query) {
    if (query.length) {
      this.showResults = true;
    } else {
      this.showResults = false;
    }
  }

  searchListing(event) {
    if (event && this.inputSelected && this.inputSelected.type === 'input') {
      this.router.navigate(['listing/results', this.inputSelected.value]);
    } else if (event && this.inputSelected && this.inputSelected.type === 'list') {
      this.router.navigate(['listing/details', this.inputSelected.value.objectID]);
    }
  }

  searchItem(hit) {
    console.log('hit', hit);
  }

  private initForm() {
    this.searchForm = this.fb.group({
      category: [[], Validators.compose([Validators.required])]
      // from: ['', Validators.compose([Validators.required])],
      // to: [{ value: '', disabled: true }, Validators.compose([Validators.required])]
    });
  }

  insights(a, b) {
    console.log('a', a);
    console.log('b', b);
  }

  onCategoryRemove(index) {
    this.inputSelected = null;
  }

  onCategorySelected(event) {
    this.inputSelected = event;
  }

  setToMinDate(event) {
    this.toMinDate = new Date(event.value);
  }

  searchList(form) {
    if (form.valid) {
      const searchedInput = {
        categories: form.value.category,
        from: form.value.from,
        to: form.controls.to.value
      };

      // Fetch data from db according to the criteria selected and format data to Listing model
      this.searchService.getSearchedList(searchedInput).subscribe(res => {
        const resStorage = [];
        for (const key in res) {
          if (!res.hasOwnProperty(key)) {
            continue;
          }
          for (const insideKey in res[key]) {
            if (!res[key].hasOwnProperty(insideKey)) {
              continue;
            }
            resStorage.push(res[key][insideKey]);
          }
        }
        const listStorage = resStorage.map(e => {
          return { id: e.payload.doc.id, ...e.payload.doc.data() } as Listing;
        });

        // Filter List and remove listing of user's own item
        if (localStorage.getItem('user') !== null) {
          const userID = JSON.parse(localStorage.getItem('user')).uid;
          this.list = listStorage.filter(x => x.user !== userID);
        } else {
          this.list = listStorage;
        }

        localStorage.setItem('searchedListings', JSON.stringify(this.list));
        this.router.navigate(['listing']);
        console.log('this.list', this.list);
      });
    } else {
      return;
    }
  }

  getBackgroundImageStyle(imageUrl: string) {
    return this.imageUtil.getBackgroundImageStyle(imageUrl);
  }

  goToDetails(listingID) {
    this.loading = true;
    this.router.navigate(['listing/details', listingID]);
  }

  changeListingText(randomNum) {
    const listingTextArr = ["camera", "dslr", "drone", "laptop", "guitar", "bicycle", "game", "dress", "table"];
    this.listingItem = listingTextArr[randomNum];
  }
}
