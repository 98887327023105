import { Injectable } from '@angular/core';
import { DEFAULT_LISTING_IMAGE } from '@app/helpers/images';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImageUrlPipe } from '../pipes/image-url.pipe';

@Injectable()
export class ImageUtil {

  constructor(
    private imageUrlPipe: ImageUrlPipe
  ) { }

  getBackgroundImageStyle(imageUrl: string): any {
    return {
      'background-image': `url('${imageUrl || DEFAULT_LISTING_IMAGE}')`
    };
  }

  getBackgroundImageStyleAsync(imageUrl: string): Observable<any> {
    return this.imageUrlPipe.transform(imageUrl)
      .pipe(map(
        finalUrl => this.getBackgroundImageStyle(finalUrl)
      ));
  }

}
