import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { PublicComponent } from './layout/public/public.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';

import { MatProgressBarModule } from '@angular/material/progress-bar';

import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from '@app/environments/environment';
import { NgbModule, NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { NgxLoadingModule } from 'ngx-loading';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routes';
import { OptInPreloadStrategy } from '@app/core/strategy/opt-in-preload.strategy';
import { PrivacyPolicyPageComponent } from './public-pages/privacy-policy-page/privacy-policy-page.component';
import { HowItWorksPageComponent } from './public-pages/how-it-works-page/how-it-works-page.component';
import { AboutUsPageComponent } from './public-pages/about-us-page/about-us-page.component';
import { TermsConditionPageComponent } from './public-pages/terms-condition-page/terms-condition-page.component';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
import { ReactiveFormsModule } from '@angular/forms';
import { VerifyEmailPageComponent } from './public-pages/verify-email-page/verify-email-page.component';
@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    NotFoundComponent,
    PrivacyPolicyPageComponent,
    HowItWorksPageComponent,
    AboutUsPageComponent,
    TermsConditionPageComponent,
    VerifyEmailPageComponent
  ],
  imports: [
    RouterModule.forRoot(AppRoutes, {
    preloadingStrategy: OptInPreloadStrategy,
    relativeLinkResolution: 'legacy'
}),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    NgbModule,
    MatProgressBarModule,
    NgbModalModule,
    NgbTooltipModule,
    NotifierModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({}),
    SharedModule,
    MatGoogleMapsAutocompleteModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDd4IuQ7WmiSgFizRUQ4tflve3gYYKSjeM',
      libraries: ['places']
    }),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-KK86RGD',
    }),
    CoreModule.forRoot(),
    ReactiveFormsModule
  ],
  providers: [
    GoogleTagManagerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
