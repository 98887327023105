export const NEW_LISTING = {
  category: [],
  description: '',
  name: '',
  images: [],
  rentalPrice: 0,
  currency: '',
  location: '',
  status: false,
  weeklyDiscount: 40,
  monthlyDiscount: 50,
  user: '',
  stripe_account_ID: ''
};

export enum CHAT_LISTING_TYPE {
  CONFIRMATION = 'CONFIRMATION',
  LISTING = 'LISTING'
}
