<div class="app-notif-item" (click)="executeAction()">
  <img [src]="appNotification.data.image | imageUrl | async" class="notification-image">
  <!-- <span>{{ appNotification.label }}</span> -->
  <span class="mat-caption ml-2">
    {{ appNotification.description }}
    <br>
    <a (click)="clickMarkAsRead($event)">Mark as {{ appNotification.unread ? '' : 'un'}}read</a>
  </span>
  <!-- <div class="d-flex flex-row" (click)="executeAction()">
    <div class="d-flex align-items-center">
    </div>
    <div class="m-auto d-flex flex-column">
      <span>{{ appNotification.label }}</span>
      <span class="mat-caption">
        {{ appNotification.description }}
      </span>
    </div>
  </div> -->
  <!-- <div class="m-auto">
    <button mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="subMenu"
      aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #subMenu="matMenu" (click)="$event.stopPropagation()">
      <button mat-menu-item (click)="clickMarkAsRead($event, menuTrigger)">
        <mat-icon>done</mat-icon>
        <span>Mark as {{ appNotification.unread ? '' : 'un'}}read</span>
      </button>
    </mat-menu>
  </div> -->
</div>