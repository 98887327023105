<!-- add item modal -->
<!-- <div class="container">
  <div class="add-item">
    <div class="modal-header">
      <h1 class="display-3 modal-title"
        style="color:#444444!important;padding-top: 0px;font-size: 42px!important;text-align: left;width: 100%;">
        Add Item
      </h1>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
        <svg class="feather close-modal-icon">
          <use xlink:href="../../../../assets/img/feather-sprite.svg#x" />
        </svg>
      </button>
    </div>
    <div class="modal-body">
      <div class="login-form-wrap">
        <div class="col-lg-12 drag-wrap">
          <app-uploader (imageUploaded)="onImageUploaded($event)"></app-uploader>
        </div>
        <div class="input-wrap">
          <form [formGroup]="basicInfoForm" (ngSubmit)="saveListing(basicInfoForm)">
            <div class="row">
              <div class="col-lg-7 col no-padding">
                <mat-form-field class="Bsuwn example-full-width">
                  <input matInput placeholder="Name" formControlName="name" value="Item Test" />
                </mat-form-field>
              </div>
              <div class="col-lg-5 col" style="padding-right:0px;">
                <select class="add-item-select">
                  <option>USD</option>
                  <option>EU</option>
                  <option>JPY</option>
                  <option>PHP</option>
                </select>
                <mat-form-field class="Bsuwn example-full-width">
                  <input matInput placeholder="Price" formControlName="rentalPrice" value="100"
                    style="padding-left: 65px;" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col col-lg-12 no-padding">
                <mat-form-field class="Bsuwn example-full-width">
                  <textarea matInput placeholder="Description" formControlName="description"></textarea>
                </mat-form-field>
                <button class="Gydhwj" [disabled]="!basicInfoForm.valid" mat-button>Create</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- add item modal end -->

<!-- start earning modal start -->
<div class="container">
  <div class="start-earning-modal">
    <div class="modal-header">
    </div>
    <div class="modal-body">
      <h2 style="text-align: center;">Start Earning…</h2>
      <label>
        Listing any items from gadgets, hardware, tools
        and etc. through RentIt® to yield passive income
        with you in mind. Our process has never been easy
        and you’re just a few steps away to get things setup.
      </label>
      <button class="Gydhwj" mat-button (click)="clickGetStarted()">
        Get Started
      </button>
    </div>
  </div>
</div>
<!-- start earning modal end -->