import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@app/core/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {
  get ifLanding(): boolean {
    return !(this.router.url.charAt(this.router.url.length - 1) !== '/');
  }
  get hideFooter(): boolean {
    return this.router.url.includes('/listing')
      || this.router.url.includes('/booking');
  }
  constructor(private ngbModal: NgbModal, public authService: AuthService, public router: Router) { }

  ngOnInit() { }
}
