<app-main-nav></app-main-nav>
<div class="container verify-email">
    <div>
        <ng-container *ngIf="!hasError">
            <img src="./assets/img/checking_bg.svg" height="400" />
            <h4>Please wait while we're verifying your request</h4>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>

        <ng-container *ngIf="hasError">
            <img src="./assets/img/notfound_bg.svg" height="400" />
            <h4>Opps your request is not valid</h4>
        </ng-container>
    </div>
</div>