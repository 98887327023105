import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { DeleteListingService } from '../services/delete-listing.service';
import { AddListingService } from '../services/add-listing.service';
import { ProfileService } from '../auth/profile.service';

@Injectable({
  providedIn: 'root'
})
export class FormCreationGuard implements CanActivate {
  private readonly notifier: NotifierService;
  private itemId: string = null;
  constructor(
    private notifierService: NotifierService,
    private router: Router,
    private deleteSvc: DeleteListingService,
    private addListingService: AddListingService,
    private profileSvc: ProfileService
  ) {
    this.notifier = notifierService;
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const stripeAccFlg = localStorage.stripeAccFlg;
    if (!stripeAccFlg || stripeAccFlg !== '201') {
      this.router.navigate(['/profile']);
      this.notifier.notify('error', 'Access Forbidden: Please create Stripe Account first.');
      return false;
    }
    const data = route.params;
    if (
      data.listingId === 'add-new' ||
      window.location.href.substring(
        window.location.href.length - 11,
        window.location.href.length
      ) === 'add-listing'
    ) {
      const userID = JSON.parse(localStorage.user).uid;
      this.profileSvc.getUser(userID).subscribe(doc => {
        this.addListingService.createNewListing(userID, doc.data().stripe_account_ID);
        // .then(result => {
        //   console.log('result', result);
        //   this.itemId = result.id;
        //   if (localStorage.user !== undefined) {
        //     this.addListingService.createUserListingRelationship(result.id, userID);
        //   }
        //   this.router.navigate(['add-listing', result.id, 'basic-info']);
        // })
        // .catch(newListingErr => {
        //   if (!(this.itemId === null) && !(this.itemId === '')) {
        //     this.deleteSvc.deleteListing(this.itemId);
        //   }
        //   console.log('newListingErr', newListingErr);
        // });
      });
    } else {
      return true;
    }
  }
}
