import { AbstractControl, ValidatorFn, FormControl } from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';

export class FormValidator {

  // check if username is already taken or not
  static validUsername(fc: FormControl) {
    // if (fc.value.toLowerCase() === "abc123@gmail.com" || fc.value.toLowerCase() === "123abc"){
    //     return ({validUsername: true});
    // } else {
    //     return (null);
    // }
  }

  // validate valid name
  static validName = (fc: FormControl) => {
    const name: string = fc.value;
    if ((new RegExp('[a-zA-Z\- ]*').test(name)) && !(name.trim() === '')) {
      return (null);
    }
    return { validName: true };
  }

  // validate phone number
  static validCountryPhone = (countryControl: AbstractControl): ValidatorFn => {
    let subscribe = false;
    return (phoneControl: AbstractControl): { [key: string]: boolean } => {

      if (!subscribe) {
        subscribe = true;
        countryControl.valueChanges.subscribe(() => {
          phoneControl.updateValueAndValidity();
        });
      }

      if (phoneControl.value !== '') {
        try {

          const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
          const phoneNumber = '' + phoneControl.value + '';
          const region = countryControl.value;
          const pNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, region.iso);
          const isValidNumber = phoneUtil.isValidNumber(pNumber);

          if (isValidNumber) {
            return undefined;
          }
        } catch (e) {
          console.log(e);
          return {
            validCountryPhone: true
          };
        }

        return {
          validCountryPhone: true
        };
      } else {
        return undefined;
      }
    };
  }

}
